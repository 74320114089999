/*
    Styles du composant [Play-button] de SipaUI
*/
@import "variables";

// LIGHT --------------------------------------------------------------------------------------/
.sipaui {
    .su-button.su-play-pause {
        &:not(.su-small) .su-icon {
            font-size: $font-size-play-pause-button-icon;
            max-height: $max-height-play-pause-button-icon;
        }
        .su-icon-play {
            display: inline-block !important;
        }
        .su-icon-pause {
            display: none !important;
        }
        &.su-play {
            .su-icon-play {
                display: none !important;
            }
            .su-icon-pause {
                display: inline-block !important;
            }
        }
        &.su-with-time, // sélecteur pour rétrocompatibilité à supprimer dans SipaUI 8
        &:has(time) {
            text-align: left;
            padding-left: $padding-left-play-pause-button !important;
            padding-right: $padding-right-play-pause-button !important;
            min-width: unset;
        }
    }
}
