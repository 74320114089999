/*
    Styles du composant [progress bar] de SipaUI
*/
@import "variables";

.sipaui {
    // PROGRESS-BAR BASE
    .su-progress-bar {
        display: block;
        width: 100%;
        height: $height-progress-bar;
        -webkit-appearance: none;
        appearance: none;
        border: none;
        background: $background-progress-bar; // Fond pour FF
        overflow: hidden;

        &::-webkit-progress-bar {
            background: $background-progress-bar; // Fond pour Webkit
        }
        &::-webkit-progress-value {
            background: $background-progress-bar-value;
            border-right: $border-right-progress-bar-value;
        }
        &::-moz-progress-bar {
            background: $background-progress-bar-value;
            border-right: $border-right-progress-bar-value;
        }
        &[value="0"]::-webkit-progress-value,
        &[value="100"]::-webkit-progress-value {
            border-right: 0;
        }
        &[value="0"]::-moz-progress-bar,
        &[value="100"]::-moz-progress-bar {
            border-right: 0;
        }
    }
    .su-media-container .su-progress-bar {
        position: absolute;
        bottom: 0;
        z-index: 1;
    }

    // PROGRESS-BAR INTERACTIVE
    input[type="range"].su-progress-bar {
        -webkit-appearance: none;
        //width: 100%;
        padding: 0;
        height: $height-progress-bar-cursor;
        margin: 0;
        outline: 0;
        background: transparent;
        cursor: pointer;

        &:disabled {
            opacity: var(--opacite-inactif);
            cursor: not-allowed;
        }
        &:focus {
            outline: none;
        }

        // Firefox
        &::-moz-range-track {
            background: $background-progress-bar;
            height: $height-progress-bar;
        }
        &::-moz-range-progress {
            background: $background-progress-bar-value;
            height: $height-progress-bar;
        }
        &::-moz-range-thumb {
            background: $background-progress-bar-cursor;
            height: $height-progress-bar-cursor;
            width: $width-progress-bar-cursor;
            border: 0;
            cursor: grab;
        }
        &:active::-moz-range-thumb {
            cursor: grabbing;
        }

        // Webkit
        &::-webkit-slider-runnable-track {
            -webkit-appearance: none;
            height: $height-progress-bar;
            background: linear-gradient($background-progress-bar 0 0) no-repeat center;
            background-size: 100% $height-progress-bar;
        }
        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            border-radius: 50%;
            height: $height-progress-bar-cursor;
            width: $width-progress-bar-cursor;
            background: $background-progress-bar-cursor;
            box-shadow: calc(-100vmax - $height-progress-bar-cursor + .4px) 0 0 100vmax $background-progress-bar-value;
            margin-top: round(calc($height-progress-bar / -2));
            clip-path: polygon(
                    100% 0,
                    3px 0,
                    0 3px,
                    -100vmax 3px,
                    -100vmax 8px,
                    0 8px,
                    3px 100%,
                    100% 100%);
            cursor: grab;
        }
        &:active::-webkit-slider-thumb {
            cursor: grabbing;
        }
        &:disabled::-webkit-slider-thumb {
            cursor: not-allowed;
        }
    }
}

// NÉGATIF
.sipaui {
    &.su-negative .su-progress-bar,
    .su-negative .su-progress-bar {
        background: $background-progress-bar-negative; // Fond pour FF

        &::-moz-progress-bar {
            background: $background-progress-bar-value-negative;
        }

        &::-webkit-progress-bar {
            background: $background-progress-bar-negative; // Fond pour Webkit
        }
        &::-webkit-progress-value {
            background: $background-progress-bar-value-negative;
        }
    }
    &.su-negative input[type="range"].su-progress-bar,
    .su-negative input[type="range"].su-progress-bar {
        &::-moz-range-track {
            background: $background-progress-bar-negative; // Fond pour FF
        }
        &::-moz-range-progress {
            background: $background-progress-bar-value-negative;
        }
        &::-moz-range-thumb {
            background: $background-progress-bar-cursor-negative;
        }

        &::-webkit-slider-runnable-track { // Fond pour Webkit
            background: linear-gradient($background-progress-bar-negative 0 0) no-repeat center;
        }
        &::-webkit-slider-thumb {
            background: $background-progress-bar-cursor-negative;
            box-shadow: calc(-100vmax - $height-progress-bar-cursor + .4px) 0 0 100vmax $background-progress-bar-value-negative;
        }
    }
}
