/*
    Styles du composant [article-in-list] de SipaUI
*/
@import "variables";

// Zone de lien sur articles dans une liste
.sipaui {
    .su-articles-list {
        padding: 0;
        margin: 0;
    }
    .su-articles-list-item {
        position: relative;

        .su-title a::before {
            content: "";
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
        .su-metadata {
            display: flex;
            flex-flow: row nowrap;
            overflow: hidden;
            column-gap: $column-gap-metadata;
            align-items: flex-start;

            .su-date {
                flex: 0 0 auto;
            }
        }
        .su-main-picture {
            background: $background-picture;

            &:has(img) {
                background: none;
            }
        }

        // Filet en bas de l’image
        &.su-card.su-thread::after,
        &[class*="su-focus"].su-thread .su-main-picture::after,
        &[class*="su-teaser"].su-thread .su-main-picture::after { // CSS pour rétrocompatibilité à supprimer dans SipaUI 8
            content: "";
            display: block;
            height: $height-card-thread;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background: $background-card-thread;
            z-index: 1;
        }
    }

    // Article sponsorisé
    &.su-sponsored-articles { // Page
        background: $background-sponsored;
    }
    .su-sponsored-articles:not(.sipaui) { // Liste
        background: $background-sponsored;
        padding: $padding-list-sponsored;
        border-radius: $border-radius-list-sponsored;
    }
    .su-articles-list-item.su-sponsored:not(.su-focus-image) {
        border-radius: $border-radius-article-sponsored;
        overflow: hidden;

        &:after { // After au lieu d’un simple background à cause d’un bug sur Chrome et FF où le background passe par-dessus la photo dans les focus-big et focus-fullpage
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -2; // Pour passer sous le -1 de l’image
            background: $background-article-sponsored;
        }
        &[aria-description] { // CSS pour rétrocompatibilité à supprimer dans SipaUI 8
            padding: $padding-article-sponsored;

            &::before {
                content: attr(aria-description);
                display: block;
                font-size: $font-size-text-sponsored;
                font-weight: $font-weight-text-sponsored;
                line-height: $line-height-text-sponsored;
                color: $color-text-sponsored;
                margin-bottom: $margin-bottom-text-sponsored;
            }
        }
    }

    .su-timeline .su-articles-list-item.su-sponsored,
    .su-ranked-list .su-articles-list-item.su-sponsored {
        border-radius: 0;
    }
}
// .sipaui.su-negative .su-articles-list-item.su-sponsored,
// .sipaui .su-negative .su-articles-list-item.su-sponsored,
// .sipaui .su-articles-list-item.su-sponsored.su-negative {
//                                                                                                 background: red;
// }

// Séparateurs
.sipaui {
    .su-articles-list {
        [class*="su-separator"] {
            position: relative;
        }
        [class*="su-separator"]::after {
            content: "";
            display: block;
            height: $height-separator;
            width: 100%;
            background: $background-separator;
            position: absolute;
        }
        .su-separator-sm-max,
        .su-separator-md-max,
        .su-separator-top-sm-max,
        .su-separator-top-md-max {
            &::after {
                display: none;
            }
        }
        .su-separator {
            margin: 0 0 calc($margin-separator * 2) !important;

            &::after {
                bottom: calc($margin-separator * -1);
            }
            &:last-child {
                margin-bottom: $margin-separator !important;
            }
        }
        .su-separator-top {
            margin: calc($margin-separator * 2) 0 0 !important;

            &::after {
                top: calc($margin-separator * -1);
            }
            &:first-child{
                margin-top: $margin-separator !important;
            }
        }
    }
}
.sipaui.su-negative .su-articles-list [class*="su-separator"]::after,
.sipaui .su-negative .su-articles-list [class*="su-separator"]::after,
.sipaui .su-articles-list.su-negative [class*="su-separator"]::after {
    background: $background-separator-negative;
}
@media screen and (max-width: $max-breakpoint-sm) {
    .sipaui {
        .su-articles-list {
            .su-separator-sm-max {
                margin: 0 0 calc($margin-separator * 2) !important;

                &::after {
                    display: block;
                    bottom: calc($margin-separator * -1);
                }
                &:last-child {
                    margin-bottom: $margin-separator !important;
                }
            }
            .su-separator-top-sm-max {
                margin: calc($margin-separator * 2) 0 0 !important;

                &::after {
                    display: block;
                    top: calc($margin-separator * -1);
                }
                &:first-child{
                    margin-top: $margin-separator !important;
                }
            }
        }
    }
}
@media screen and (max-width: $max-breakpoint-md) {
    .sipaui {
        .su-articles-list {
            .su-separator-md-max {
                margin: 0 0 calc($margin-separator * 2) !important;

                &::after {
                    display: block;
                    bottom: calc($margin-separator * -1);
                }
                &:last-child {
                    margin-bottom: $margin-separator !important;
                }
            }
            .su-separator-top-md-max {
                margin: calc($margin-separator * 2) 0 0 !important;

                &::after {
                    display: block;
                    top: calc($margin-separator * -1);
                }
                &:first-child{
                    margin-top: $margin-separator !important;
                }
            }
        }
    }
}
.sipaui {
    .su-no-ending-separator {
        .su-separator,
        .su-separator-sm-max,
        .su-separator-md-max {
            &:last-child {
                margin-bottom: 0 !important;

                &::after {
                    display: none !important;
                }
            }
        }
        .su-separator-top,
        .su-separator-top-sm-max,
        .su-separator-top-md-max {
            &:first-child {
                margin-top: 0 !important;

                &::after {
                    display: none !important;
                }
            }
        }
    }
}
