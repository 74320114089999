/*
    Styles du composant [Person-card] de SipaUI
*/
@import "variables";

.sipaui {
    .su-person-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        
        figure {
            position: relative;
            overflow: hidden;
            width: $width-figure;
            height: $height-figure;
            border-radius: $border-radius-figure;   
            background: $background-figure;
            
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
        .su-name {
            font-family: $font-family-nom; //pour surcharger les h2 et h3
            font-size: $font-size-nom;
            font-weight: $font-weight-nom;
            line-height: $line-height-nom;
            margin-top: $margin-top-nom;
            
            a {
                border: none;
                text-decoration: none;
                color: $color-lien-nom;
                
                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    z-index: 1;
                }
            }
            &:has(+ div, + p, + span:not(.su-button)) {
                margin-bottom: $margin-bottom-nom;
            }
        }
        [class*="su-flag"],
        .su-unit {
            position: absolute;
            top: $top-unite;
            transform: $transform-unite;
            max-width: $max-width-unite;
            max-height: $max-height-unite;
        }
        .su-button {
            margin-top: $margin-top-bouton;
        }
        &:has(a) {
            &:hover {
                opacity: $opacity-hover;
            }
        }
    }
    .su-article { // pour surcharger dans su-article;
        .su-person-card {
            figure {
                margin: 0;
            }
            h2, h3 {
                margin-bottom: 0;
            }
        }
    }
    .su-negative .su-person-card,
    .su-negative.su-person-card {
        .su-name a {
            color: $font-color-negative;
        }
    }
}
