@import "palette";

// MODE ----------------------------------------------------------------------------------
:root {
    color-scheme: light;
}

// COULEURS ----------------------------------------------------------------------------------
:root {
    // PALETTE CHARTE
    --blanc:                    #{$blanc};
    --quasi-blanc:              #{$quasi-blanc};
    --gris-plus-clair:          #{$gris-plus-clair};
    --gris-clair:               #{$gris-clair};
    --gris:                     #{$gris};
    --gris-fonce:               #{$gris-fonce};
    --gris-plus-fonce:          #{$gris-plus-fonce};
    --anthracite:               #{$anthracite};
    --quasi-noir:               #{$quasi-noir};
    --noir:                     #{$noir};
    // Rouges
    --rouge-1:                  #{$rouge-1};
        --rouge:                    #{$rouge-1}; // CSS pour rétrocompatibilité à supprimer dans SipaUI 8
    --rouge-2:                  #{$rouge-2};
    --rouge-3:                  #{$rouge-3};
    --rouge-4:                  #{$rouge-4};
    // Jaunes
    --jaune-1:                  #{$jaune-1};
        --jaune:                    #{$jaune-1}; // CSS pour rétrocompatibilité à supprimer dans SipaUI 8
    --jaune-2:                  #{$jaune-2};
    --jaune-3:                  #{$jaune-3};
    --jaune-4:                  #{$jaune-4};
    // Verts
    --vert-1:                   #{$vert-1};
        --vert:                     #{$vert-1}; // CSS pour rétrocompatibilité à supprimer dans SipaUI 8
    --vert-2:                   #{$vert-2};
    --vert-3:                   #{$vert-3};
    --vert-4:                   #{$vert-4};
    // Bleus nuit
    --bleuet-1:                 #{bleuet-1};
    // --bleuet-2:                 #{bleuet-2};
    // --bleuet-3:                 #{bleuet-3};
    // --bleuet-4:                 #{bleuet-4};


    // PALETTE SÉMANTIQUE
    --couleur-abo-1:            #{$jaune-1};
    --couleur-abo-2:            #{$jaune-2};

    --couleur-erreur-1:         #{$rouge-1};

    --couleur-succes-1:         #{$vert-1};

    --couleur-neutre-1:         #{$anthracite};
    --couleur-neutre-2:         #{$blanc};

    --couleur-sponso-1:         #{$gris-clair};   // Articles sponsorisés (Communication)

    --couleur-projecteur-1:     #{$bleuet-1};   // Articles mis en avant
    --couleur-projecteur-2:     #{$bleuet-2};

    // MARQUES SIPA
    --couleur-of-1:             #{$rouge-1};          // Ouest-france
    --couleur-of-2:             #{$rouge-2};
    --couleur-co-1:             #{$rouge-1};          // Courrier de l’Ouest
    --couleur-co-2:             #{$rouge-2};
    --couleur-po-1:             #{$bleu-jdlo-1};      // Presse Océan
    --couleur-po-2:             #{$bleu-jdlo-2};
    --couleur-ml-1:             #{$bleu-jdlo-1};      // Maine Libre
    --couleur-ml-2:             #{$bleu-jdlo-2};

    --couleur-vv-1:             #{$bleu-vv-1};        // Voiles & voiliers
    --couleur-vv-2:             #{$bleu-vv-2};

    --couleur-im-1:             #{$rouge-im-1};       // Infomer Le Marin
    --couleur-im-2:             #{$rouge-im-2};

    --couleur-bc-1:             #{$bleu-bc-1};        // Bretons en cuisine
    --couleur-bc-2:             #{$bleu-bc-2};

    --couleur-br-1:             #{$noir-br-1};        // Bretons
    --couleur-br-2:             #{$noir-br-2};

    --couleur-vo-1:             #{$bleu-vo-1};        // Voyages à l'Ouest
    --couleur-vo-2:             #{$bleu-vo-2};

    --couleur-lm-1:             #{$rouge-lm-1};       // Lire Magazine
    --couleur-lm-2:             #{$rouge-lm-2};

    --couleur-tj-1:             #{$rouge-tj-1};       // Terre de jardin
    --couleur-tj-2:             #{$rouge-tj-2};

    --couleur-pdld-1:           #{$bleu-pdld-1};      // Prix de la Démocratie
    --couleur-pdld-2:           #{$anthracite};

    --couleur-ic-1:             #{$bleu-clair-ic};    // Infoconnect
    --couleur-ic-2:             #{$gris-bleu-ic-1};
    --couleur-ic-3:             #{$gris-bleu-ic-2};


    // RÉSEAUX SOCIAUX ET MARQUES EXTERNES
    --amazon-music:      #34D1D9;
    --apple:             #000;
    --apple-podcast:     #852DC4;
    --bluesky:           #1185FE;
    --dailymotion:       #333436;
    --deezer:            #A238FF;
    --facebook:          #1877f2;
    --flickr:             #0063dc;
    --flipboard:          #e12828;
    --fluxrss:            #f0801a;
    --google:            #db4437;
    --instagram:         #fd0075;
    --linkedin:          #2867b2;
    --pinterest:         #e60023;
    --snapchat:          #fffc00;
    --soundcloud:        #f50;
    --spotify:           #1ED760;
    --tumblr:            #000;
    --twitch:            #6441a4;
    --viadeo:            #f07355;
    --whatsapp:          #28d146;
    --x:                 #000;
    --youtube:           #f00;
}

// TYPO ----------------------------------------------------------------------------------
:root {
    --font-size-xxs:  1rem;
    --font-size-xs:   1.2rem;
    --font-size-s:    1.4rem;
    --font-size-ms:   1.6rem;
    --font-size-m:    1.8rem;
    --font-size-ml:   2rem;
    --font-size-l:    2.2rem;
    --font-size-xl:    2.4rem;
    --font-size-xxl:   3rem;
    --font-size-xxxl:  4rem;

    --font-weight-thin:   200;
    --font-weight-normal: 400;
    --font-weight-medium: 500;
    --font-weight-bold:   700;

    --line-height-xs: 1;
    --line-height-s:  1.2;
    --line-height-m:  1.35;
    --line-height-l:  1.5;
    --line-height-xl: 2;
}

// HAUTEUR STANDARD ----------------------------------------------------------------------
$size-touch: 44px;
$size-touch-sm: 34px;
:root {
    --taille-tactile: #{$size-touch};
    --taille-tactile-mini: #{$size-touch-sm};
}

// MARGES --------------------------------------------------------------------------------
// Marges en PX
:root {
    --marge-xs:    5px;
    --marge-s:     10px;
    --marge-m:     20px;
    --marge-l:     30px;
    --marge-xl:    40px;
    --marge-xxl:   50px;
    --marge-xxxl: 100px;
}


// Coins arrondis
:root {
    --border-radius-standard:   8px;
    --border-radius-small:      3px;
}

// disabled
:root {
    --opacite-inactif: .5;
}

// hover
:root {
    --opacite-survol: .7;
}
