/*
    Styles du composant [views] de SipaUI
*/
@import "variables";


// VUES GÉNÉRIQUE-----------------------------------------------------------------------------------------/

.sipaui {
    .su-views {
        white-space: nowrap;
        font-size: $font-size-views;
        line-height: $line-height-views;
        color: $color-views;
    }
    .su-article {
        .su-views {
            font-size: $font-size-views-edito;
        }
    }

    // COULEUR NÉGATIVE -------------------------------------/
    &.su-negative .su-views,
    .su-negative .su-views,
    .su-negative.su-views {
        color: $font-color-negative;

        &::before {
            background: $font-color-negative;
        }
    }
}
