/*
    Styles du composant [pop-ins] de SipaUI
*/
@import "variables";

// Animation d’ouverture
@keyframes animation-pop-in {
  0%{
    opacity:0;
    transform: scale(0);
  }
  100%{
    opacity:1;
    transform: scale(1);
  }
}

.sipaui {
    .su-pop-in { // styles visuels des pop-in
        width: 100%;
        max-height: calc(100vh - var(--marge-m) * 2);
        max-width: calc(100vw - var(--marge-m) * 2);
        padding: $padding-popin;
        overflow: hidden;
        flex-direction: column;
        background: $background-pop-in;
        border: $border-pop-in;
        border-radius: $border-radius-pop-in;

        &.su-pop-in-in {
            opacity: 1;
            transform: translate(-50%, -50%) scale(1);
        }
        &.su-pop-in-out {
            opacity: 0;
            transform: translate(-50%, -50%) scale(0);
        }
        .su-close-area {
            min-height: $min-height-close-area;
        }
        .su-close {
            position: absolute;
            top: $top-croix-fermeture-pop-in;
            right: $right-croix-fermeture-pop-in;
        }
        .su-content-area {
            overflow-y: auto;
        }
        p {
            margin-bottom: var(--marge-s);
        }
        p:last-child {
            margin-bottom: 0;
        }
        .su-buttons-area {
            margin-top: var(--marge-m);
            display: flex;
            flex: 1 0;
            overflow: hidden;
            min-height: var(--taille-tactile); // pour contrer un bug Safari : cet élément n’a pas de hauteur quand il est dans un <dialog>
        }
    }
    .su-pop-in { // Gestion de l’ouverture
        opacity: 0;
        
        &[open] {
            display: flex;
            animation: $animation-pop-in;
        }
        &::backdrop {
            background: $backdrop-color;
            backdrop-filter: $backdrop-filter;
        }
    }
}
// CSS permettant de bloquer le scroll quand la popin est ouverte, mais cela pourrait représenter un risque au niveau des perf…
// body:has(dialog.su-no-body-scroll[open]) { ancien sélecteur pour rendre le no-scroll facultatif en raison des perf.
body:has(dialog.su-pop-in[open]) {
    overflow: hidden;
}

@media screen and (min-width: $min-breakpoint-sm) {
	.sipaui {
        .su-pop-in {
            max-width: calc(100vw - var(--marge-l) * 2);
        }
    }
}
@media screen and (max-width: $max-breakpoint-sm) {
	.sipaui {
        .su-pop-in {
            .su-buttons-area {
                flex-direction: column;
                align-items: stretch;
                
                &:has(button + button) { // pour contrer un bug Safari : cet élément n’a pas de hauteur quand il est dans un <dialog>
                    min-height: calc(var(--taille-tactile) * 2 + var(--marge-m));
                }
                li button {
                    width: 100%;
                }
            }
            .su-primary,
            li:has(.su-primary) {
                order: 1;
            }
            .su-secondary,
            li:has(.su-secondary) {
                order: 2;
            }
            .su-secondary {
                margin-top: var(--marge-m);
            }
        }
    }
}

@media screen and (min-width: $min-breakpoint-md) {
	.sipaui {
        .su-pop-in {
            max-width: 620px;
            padding-left: $padding-popin-desktop;
            padding-right: $padding-popin-desktop;

            .su-buttons-area {
                justify-content: flex-end
            }
            .su-secondary {
                margin-right: var(--marge-m);
            }
        }
    }
}
