/*
    Styles du composant [Button tab] de SipaUI
*/
@import "variables";

.sipaui {
    .su-buttons-tab {
        display: flex;
        flex-flow: row wrap;
        padding: 0;
        margin: 0;
        gap: $gap-buttons-tab;
        
        li {
            white-space: nowrap;
        }
        input {
            display: none;
        }
        label,
        a {
            display: inline-block;
            height: $height-button-tab;
            line-height: $height-button-tab;
            padding: $padding-button-tab;
            margin: 0;
            border-radius: $border-radius-button-tab;
            border: $border-button-tab;
            background: $background-button-tab;
            font-size: $font-size-button-tab;
            font-style: $font-style-button-tab;
            font-weight: $font-weight-button-tab;
            color: $color-button-tab;
            opacity: $opacity-button-tab;
            cursor: pointer;
            //CSS pour bloquer le contenu trop long
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 100%;
            vertical-align: top; //empêche une "fausse marge" basse provoquée par l’overflow
        }
        a:not(.su-active):hover,
        input:not(:checked)+label:hover {
            border-color: $border-color-button-tab-hover;
            background: $background-button-tab-hover;
            color: $color-button-tab-hover;
            opacity: $opacity-button-tab-hover;
        }
        a:not(.su-active):focus,
        input:not(:checked)+label:focus {
            border-color: $border-color-button-tab-focus;
            background: $background-button-tab-focus;
            color: $color-button-tab-focus;
            opacity: $opacity-button-tab-focus;
        }
        a:not(.su-active):active,
        input:not(:checked)+label:active {
            border-color: $border-color-button-tab-active;
            background: $background-button-tab-active;
            color: $color-button-tab-active;
            opacity: $opacity-button-tab-active;
        }
        // Sélectionné
        input:checked+label,
        a.su-active {
            border-color: $border-color-button-tab-checked;
            background: $background-button-tab-checked;
            color: $color-button-tab-checked;
            cursor: default;
        }
    }
}

// CARROUSEL
@media (hover: none) {
    .su-carrousel:has(.su-navigation-button + .su-navigation-button:not(.su-inactive)) .su-buttons-tab {
        mask-image: $mask-image-carrousel-buttons-tab;
    }
}

// NEGATIF

.sipaui.su-negative .su-buttons-tab,
.sipaui .su-negative .su-buttons-tab {
    label,
    a {
        border-color: $border-color-button-tab-negative;
        background: $background-button-tab-negative;
        color: $color-button-tab-negative;
    }
    a:not(.su-active):hover,
    input:not(:checked)+label:hover {
        border-color: $border-color-button-tab-hover-negative;
        background: $background-button-tab-hover-negative;
        color: $color-button-tab-hover-negative;
    }
    a:not(.su-active):focus,
    input:not(:checked)+label:focus {
        border-color: $border-color-button-tab-focus-negative;
        background: $background-button-tab-focus-negative;
        color: $color-button-tab-focus-negative;
    }
    a:not(.su-active):active,
    input:not(:checked)+label:active {
        border-color: $border-color-button-tab-active-negative;
        background: $background-button-tab-active-negative;
        color: $color-button-tab-active-negative;
    }
    // Sélectionné
    input:checked+label,
    a.su-active {
        border-color: $border-color-button-tab-checked-negative;
        background: $background-button-tab-checked-negative;
        color: $color-button-tab-checked-negative;
    }
}
