/*
    Styles du composant [focus] de SipaUI
*/
@import "variables";


// FOCUS STANDARD
.sipaui {
    .su-focus {
        .su-media-container {
            margin-bottom: $margin-bottom-figure-focus;
        }
        .su-title:has(+ div, + p, + span) {
            margin-bottom: $margin-bottom-titre-focus;
        }
        .su-title:has(+ .su-standfirst) {
            margin-bottom: $margin-bottom-titre-focus-with-standfirst;
        }
        .su-standfirst {
            margin-bottom: $margin-bottom-standfirst-focus;
        }
    }
}
@media screen and (max-width: $max-breakpoint-md) {
    .sipaui {
        .su-focus {
            figure:has(.su-visually-hidden) {
                display: none;

                + [class*="su-media-icons"] {
                    display: none;
                }
            }
        }
    }
}

// GRAND FOCUS & PLEINE PAGE
.sipaui {
    .su-focus-big,
    .su-focus-fullpage {
        display: flex;
        flex-flow: column nowrap;

        .su-media-container {
            order: 3;
            z-index: -1; // Pour éviter un bug sur FF et Chrome (pas Safari) où le hover clignote…
        }
        figure:has(.su-visually-hidden) { // Cas où l’image est absente et remplacée par un span avec su-visually-hidden
            display: none;

            + [class*="su-media-icons"] {
                display: none;
            }
        }
        .su-title {
            order: 1;
            margin-bottom: $margin-bottom-titre-grand-focus;
            font-size: $font-size-titre-grand-focus;
            line-height: $line-height-titre-grand-focus;
        }
        .su-standfirst {
            display: none;
        }
        .su-metadata {
            order: 2;
            margin-bottom: $margin-bottom-metadata-grand-focus;
        }
    }
}
@media screen and (min-width: $min-breakpoint-md) {
    .sipaui {
        .su-focus-big,
        .su-focus-fullpage {
            .su-title {
                font-size: $font-size-titre-grand-focus-md;
            }
        }
        .su-focus-fullpage {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto minmax(0,1fr);
            gap: var(--marge-s) var(--marge-m);

            .su-media-container {
                order: 1;
                grid-area: 1/1/3/2;
            }
            .su-title {
                order: 2;
                margin-bottom: 0 !important;
            }
            .su-standfirst {
                display: block;
                order: 3;
            }
            .su-metadata {
                order: 4;
                margin-bottom: 0;
            }
        }
        .su-focus-fullpage:has(.su-standfirst) {
            grid-template-rows: auto auto minmax(0,1fr);

            .su-media-container {
                grid-area: 1/1/4/2;
            }
        }
    }
}
@media screen and (min-width: $min-breakpoint-xl) {
    .sipaui {
        .su-focus-big,
        .su-focus-fullpage {
            .su-title {
                font-size: $font-size-titre-grand-focus-xl;
            }
        }
    }
}

// FOCUS IMAGE
.sipaui {
    .su-focus-image {
        overflow: hidden;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $background-focus-image;
            border-radius: $border-radius-focus-image;
        }

        .su-title,
        .su-metadata,
        [class*="su-media-icons"] {
            position: absolute;
            z-index: 1;
        }
        .su-title {
            bottom: 0;
            left: 0;
            width: 100%;
            padding: $padding-titre-focus-image;

            a {
                overflow: hidden;

                @supports (display: -webkit-box) {
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                }
            }
        }
        &:has(.su-metadata) .su-title {
            padding-bottom: $padding-bottom-titre-focus-image-avec-metadata;
        }
        .su-metadata {
            bottom: $bottom-metadata-focus-image;
            left: $left-metadata-focus-image;
        }
        .su-title a {
            color: $color-texte-focus-image;

            &:hover {
                color: $color-texte-hover-focus-image;
            }
        }
        &.su-max-line-2 {
            .su-title {
                a {
                    @supports (display: -webkit-box) {
                        -webkit-line-clamp: 2;
                    }
                }
            }
        }
    }
}

// FOCUS SPONSORISÉ
.sipaui {
    .su-sponsored {
        &.su-focus,
        &.su-focus-big,
        &.su-focus-fullpage {
            padding: $pading-focus-sponsorised;

            .su-main-picture {
                border-radius: 0;
            }
        }
        &.su-focus {
            .su-media-container {
                margin: $margin-media-container-focus-sponsorised;
            }        }
        &.su-focus-big,
        &.su-focus-fullpage {
            .su-media-container {
                margin: $margin-media-container-focus-big-fullpage-sponsorised;
            }
        }
    }
}

@supports (display: -webkit-box) {
    .sipaui .su-focus-image.su-premium .su-title a {
        display: inline;
    }
}

@media screen and (min-width: $min-breakpoint-md) {
    .sipaui {
        .su-sponsored {
            &.su-focus-fullpage {
                .su-media-container {
                    margin: $margin-media-container-focus-fullpage-desktop-sponsorised;
                }
            }
        }
    }
}
