/*
    Styles du composant [user-button] de SipaUI
*/
@import "variables";

// BASE  ------------------------------------------------------------------------------------------/
.sipaui {
    .su-user {
        display: inline-block;
        position: relative;
            height: $height-user;
        
        .su-connection,
        .su-show-menu {
            font-family: $font-family-user;
            font-weight: $font-weight-user;
            font-size: $font-size-user;
            height: $height-user;
            line-height: $height-user;
            text-transform: $text-transform-user;
            padding: $padding-user;

            &::before {
                content: 'utilisateur';
                font-family: su-icons;
                position: absolute;
                top: $top-user-icon;
            }
        }
       .su-connection {
            display: inline-block;
            background: $background-connection;
            color: $color-connection;
            border: $border-connection;
            box-shadow: $shadow-connection;
        }
        .su-show-menu {
            display: none;
            background: $background-show-menu;
            color: $color-show-menu;
            border: $border-show-menu;
            box-shadow: $shadow-show-menu;
        }
        &:not(.su-disabled) {
            .su-connection {
                &:hover,
                &:focus {
                    background: $background-connection-hover;
                    color: $color-connection-hover;
                    border: $border-connection-hover;
                    box-shadow: $shadow-connection-hover;
                    opacity: $opacity-connection-hover;
                }
                &:active {
                    background:$background-connection-active;
                    color: $color-connection-active;
                    border: $border-connection-active;
                    box-shadow: $shadow-connection-active;
                    opacity: $opacity-connection-active;
                }
            }
            .su-show-menu {
                &:hover,
                &:focus {
                    background: $background-show-menu-hover;
                    color: $color-show-menu-hover;
                    border: $border-show-menu-hover;
                    box-shadow: $shadow-show-menu-hover;
                    opacity: $opacity-show-menu-hover;
                }
                &:active {
                    background:$background-show-menu-active;
                    color: $color-show-menu-active;
                    border: $border-show-menu-active;
                    box-shadow: $shadow-show-menu-active;
                    opacity: $opacity-connection-active;
                }
            }
        }
        &.su-disabled .su-button {
            cursor: default !important;
        }
    }
}

// CONNECTÉ ------------------------------------------------------------------------------------------/
.sipaui {
    .su-connected .su-user,
    .su-user.su-connected {
        .su-connection {
            display: none;
        }
        .su-show-menu {
            display: inline-block;
        }
    }
}

// MOBILE  ------------------------------------------------------------------------------------------/
@media screen and (max-width: $max-breakpoint-sm) {
    .sipaui {
        .su-user {
            .su-connection,
            .su-show-menu {
                width: $width-user-mobile;
                min-width: $min-width-user-mobile;
                padding: $padding-user-mobile;
                border-radius:  $border-radius-user-mobile;
                
                &::before {
                    left: 50%;
                    transform: translateX(-50%);
                    font-size: $font-size-user-icon-mobile;
                }
                span {
                    display: block;
                    width: 1px;
                    height: 1px;
                    font-size: 1px;
                    opacity: 0;
                }
            }
        }
    }
}

// DESKTOP  ------------------------------------------------------------------------------------------/
@media screen and (min-width: $min-breakpoint-md) {
    .sipaui {
        .su-user {
            .su-connection,
            .su-show-menu {
                border-radius: $border-radius-user-desktop;
                
                &::before {
                    font-size: $font-size-user-icon-desktop;
                    left: $left-user-icon-desktop;
                }
            }
            .su-show-menu {
                padding-right: $padding-right-show-menu;
                
                &::after {
                    content: 'déplier';
                    font-family: su-icons;
                    position: absolute;
                    top: $top-show-menu-arrow-desktop;
                    right: $right-show-menu-arrow-desktop;
                    font-size: $font-size-show-menu-arrow-desktop;
                }
            }
        }
    }
}
