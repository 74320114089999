/*
    Styles du composant [carrousel] de SipaUI
*/
@import "variables";

.sipaui {
    .su-carrousel {
        position: relative;
        height: auto;
        width: 100%;

        .su-carrousel-container {
            display: flex;
            flex-wrap: nowrap;
            -webkit-overflow-scrolling: touch;
            overflow-x: auto;
            scroll-padding: 1rem;
            scrollbar-width: none;
            width: 100%;

            .su-carrousel-item {
                margin: 0;
            }
        }
        .su-carrousel-container::-webkit-scrollbar {
            display: none;
        }

        &[class*="su-carrousel-items-"] {
            .su-carrousel-item {
                flex: 0 0 auto;
            }
        }
        &.su-carrousel-items-xs {
            .su-carrousel-item {
                width: $width-carrousel-item-xs;
            }
        }
        &.su-carrousel-items-s {
            .su-carrousel-item {
                width: $width-carrousel-item-s;
            }
        }
        &.su-carrousel-items-m {
            .su-carrousel-item {
                width: $width-carrousel-item-m;
            }
        }
        &.su-carrousel-items-l {
            .su-carrousel-item {
                width: $width-carrousel-item-l;
            }
        }
        &.su-carrousel-items-xl {
            .su-carrousel-item {
                width: $width-carrousel-item-xl;
            }
        }
        &.su-carrousel-items-xxl {
            .su-carrousel-item {
                width: $width-carrousel-item-xxl;
            }
        }
        .su-carrousel-container:not([data-suswipe*='"rambo":true']) ~ .su-navigation-button.su-inactive {
            display: none;
        }
        .su-navigation-button {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
        }
        .su-right {
            right: 0;
        }

        &.su-equal {
            li.su-carrousel-item > * {
                height: 100%;
            }
        }
        &.su-align-top {
            .su-carrousel-container {
                align-items: flex-start;
            }
        }
        &.su-align-center {
            .su-carrousel-container {
                align-items: center;
            }
        }
        &.su-align-bottom {
            .su-carrousel-container {
                align-items: flex-end;
            }
        }
    }
    .su-carrousel-container { // cette déclaration est sortie de .carrousel afin qu’elle soit plus facilement surchargeable.
        gap: $gap-carrousel-container;
    }

    // Carrousel accroché au bords de l’écran en mobile (cf. .su-stretched-mobile dans les helpers)
    .su-stretched-mobile {
        .su-carrousel,
        &.su-carrousel {
            width: auto;
        }
    }
    // Carrousel accroché au bords d’un bloc
    .su-stretch-to-bloc-m .su-carrousel,
    .su-carrousel.su-stretch-to-bloc-m {
        margin-left: calc($margin-carrousel-streched-m * -1);
        margin-right: calc($margin-carrousel-streched-m * -1);
        width: auto;

        .su-carrousel-item:first-child {
            margin-left: $margin-carrousel-streched-m;
        }
        .su-carrousel-item:last-child {
            margin-right: $margin-carrousel-streched-m;
        }
    }
    .su-stretch-to-bloc-l .su-carrousel,
    .su-carrousel.su-stretch-to-bloc-l {
        margin-left: calc($margin-carrousel-streched-l * -1);
        margin-right: calc($margin-carrousel-streched-l * -1);
        width: auto;

        .su-carrousel-item:first-child {
            margin-left: $margin-carrousel-streched-l;
        }
        .su-carrousel-item:last-child {
            margin-right: $margin-carrousel-streched-l;
        }
    }
}

@media screen and (min-width: $min-breakpoint-md) {
    .sipaui .su-carrousel.su-carrousel-grid {
        &[class*="su-carrousel-items-"] {
            .su-carrousel-item {
                width: inherit;
            }
        }
    }
}

@media (hover: none) or (pointer: coarse) {
    .sipaui .su-carrousel {
        .su-carrousel-container {
            -webkit-scroll-snap-type: x mandatory;
            scroll-snap-type: x mandatory;

            .su-carrousel-item {
                scroll-snap-align: center
            }
            .su-carrousel-item:first-of-type {
                scroll-snap-align: start
            }
            .su-carrousel-item:last-of-type {
                scroll-snap-align: end
            }
        }
        .su-navigation-button {
            display: none;
        }
    }
}
















