/*
    Styles du composant [carrousel] de SipaUI
*/
@import "variables";

.sipaui {
    .su-carrousel {
        position: relative;
        height: auto;
        width: 100%;
        
        .su-carrousel-container {
            display: flex;
            flex-wrap: nowrap;
            -webkit-overflow-scrolling: touch;
            overflow-x: auto;
            scroll-padding: 1rem;
            scrollbar-width: none;
            width: 100%;
            
            .su-carrousel-item {
                margin: 0;
            }
        }
        .su-carrousel-container::-webkit-scrollbar {
            display: none;
        }
        
        &[class*="su-carrousel-items-"] {
            .su-carrousel-item {
                flex: 0 0 auto;
            }
        }
        &.su-carrousel-items-xs {
            .su-carrousel-item {
                width: $width-carrousel-item-xs;
            }
        }
        &.su-carrousel-items-s {
            .su-carrousel-item {
                width: $width-carrousel-item-s;
            }
        }
        &.su-carrousel-items-m {
            .su-carrousel-item {
                width: $width-carrousel-item-m;
            }
        }
        &.su-carrousel-items-l {
            .su-carrousel-item {
                width: $width-carrousel-item-l;
            }
        }
        &.su-carrousel-items-xl {
            .su-carrousel-item {
                width: $width-carrousel-item-xl;
            }
        }
        .su-carrousel-container:not([data-suswipe*='"rambo":true']) ~ .su-navigation-button.su-inactive {
            display: none;
        }
        .su-navigation-button {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
        }
        .su-right {
            right: 0;
        }
    }
    .su-carrousel-container { // cette déclaration est sortie de .carrousel afin qu’elle soit plus facilement surchargeable.
        gap: 20px;
    }
}

@media screen and (min-width: $min-breakpoint-md) {
    .sipaui .su-carrousel.su-carrousel-grid {
        &[class*="su-carrousel-items-"] {
            .su-carrousel-item {
                width: inherit;
            }
        }
    }
}

@media (hover: none) {
    .sipaui .su-carrousel {
        .su-carrousel-container {
            -webkit-scroll-snap-type: x mandatory;
            scroll-snap-type: x mandatory;
            
            .su-carrousel-item {
                scroll-snap-align: center
            }
            .su-carrousel-item:first-of-type {
                scroll-snap-align: start
            }
            .su-carrousel-item:last-of-type {
                scroll-snap-align: end
            }
        }
        .su-navigation-button {
            display: none;
        }
    }
}
















