/*
    Styles du composant [channel] de SipaUI
*/
@import "variables";

.sipaui {
    .su-channel {
        font-size: $font-size-channel;
        font-weight: $font-weight-channel;
        line-height: $line-height-channel;
        text-transform: $text-transform-channel;
        color: $color-channel;
        margin: 0;
        background: $background-channel;
        padding: $padding-channel;
        border: $border-channel;

        a:link {
            color: $color-channel;
            border: $border-channel-lien;
            text-decoration: $text-decoration-channel-lien;
            padding: $padding-channel-lien;
        }
    }
    .su-article {
        .su-channel {
            font-size: $font-size-channel-edito;
        }
    }

    // COULEUR NÉGATIVE -------------------------------------/
    &.su-negative .su-channel,
    .su-negative .su-channel,
    .su-negative.su-channel {
        color: $color-channel-negative;

        a:link {
            color: $color-channel-negative;
        }
    }
}
