/*
    Styles du composant [date] de SipaUI
*/
@import "variables";


// DATE GÉNÉRIQUE-----------------------------------------------------------------------------------------/

// $mask-icon-date: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj48c3ZnIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxMiAxMiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxuczpzZXJpZj0iaHR0cDovL3d3dy5zZXJpZi5jb20vIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjI7Ij48Zz48cGF0aCBkPSJNNiwwYy0zLjI5MiwwIC02LDIuNzA4IC02LDZjMCwzLjI5MiAyLjcwOCw2IDYsNmMzLjI5MiwwIDYsLTIuNzA4IDYsLTZjMCwtMy4yOTIgLTIuNzA4LC02IC02LC02Wm0wLDExYy0yLjc0MywwIC01LC0yLjI1NyAtNSwtNWMtMCwtMi43NDMgMi4yNTcsLTUgNSwtNWMyLjc0MywtMCA1LDIuMjU3IDUsNWMwLDIuNzQzIC0yLjI1Nyw1IC01LDVaIiBzdHlsZT0iZmlsbC1ydWxlOm5vbnplcm87Ii8+PHBhdGggZD0iTTYsNmwwLC00bC0xLDBsMCw1bDMsMGwwLC0xbC0yLDBaIiBzdHlsZT0iZmlsbC1ydWxlOm5vbnplcm87Ii8+PC9nPjwvc3ZnPg==);

.sipaui {
    .su-date {
        font-size: $font-size-date;
        line-height: $line-height-date;
        color: $color-date;
        margin: 0;
    }
    .su-article {
        .su-date {
            font-size: $font-size-date-edito;
            // &::before {
            //     content: '';
            //     background: $color-icon-date;
            //     display: inline-block;
            //     width: 12px;
            //     height: 12px;
            //     transform: translateY(2px);
            //     margin-right: 3px;
            //     -webkit-mask: $mask-icon-date;
            //     mask: $mask-icon-date;
            //     -webkit-mask-size: cover;
            //     mask-size: cover;
            // }
        }
    }
    
    // COULEUR NÉGATIVE -------------------------------------/
    &.su-negative .su-date,
    .su-negative .su-date,
    .su-negative.su-date {
        color: $color-date-negative;
        
        // &::before {
        //     background: $font-color-negative;
        // }
    }
}
