/*
    variables liées au composant [Article]
    ex:
    $border-button-premium: 3px !default;
*/

:root {
    // --couleur-filet-thematique: var(--couleur-of-1);
    --couleur-filet-thematique: #{$color-secondary-1};
}

// Métadonnées
$margin-left-metadata:                  null                                !default;
$column-gap-metadata:                   var(--marge-s)                      !default;

// Article sponsorisé
$background-sponsored:                   $color-sponsorised-1               !default;
$padding-list-sponsored:                 var(--marge-l)                     !default;
$border-radius-list-sponsored:           $border-radius-standard            !default;
$background-article-sponsored:           $background-sponsored              !default;
$padding-article-sponsored:              var(--marge-s)                     !default;
$border-radius-article-sponsored:        $border-radius-list-sponsored      !default;

$font-size-text-sponsored:               var(--font-size-xs)                !default;
$font-weight-text-sponsored:             $font-weight-standard              !default;
$line-height-text-sponsored:             var(--line-height-m)               !default;
$color-text-sponsored:                   $color-main-6                      !default;
$margin-bottom-text-sponsored:           var(--marge-s)                     !default;

// Image
$background-picture:                    $color-main-2                       !default;

// Filet
$height-card-thread:                    5px                                 !default;
$background-card-thread:                var(--couleur-filet-thematique)      !default;

// Séparateur
$margin-separator:                      var(--marge-m)                      !default;
$height-separator:                      1px                                 !default;
$background-separator:                  $color-main-4                       !default;
$background-separator-negative:         $color-main-5                       !default;
