/*
    Styles du composant [navigation-buttons] de SipaUI
*/
@import "variables";

.sipaui {
    .su-navigation-button {
        position: relative;
        cursor: pointer;
        
        .su-icon {
            position: absolute;
            top: 50%;
            left: 50%;
        }
        &.su-inside {
            background: $background-navigation-buttons-inside;
            border: $border-navigation-buttons-inside;
            width: $width-navigation-buttons-inside;
            height: $height-navigation-buttons-inside;
            border-radius: $border-radius-navigation-buttons-inside;
            box-shadow: $box-shadow-navigation-buttons-inside;
            
            .su-icon {
                color: $color-navigation-buttons-inside-icon;
                transform: $transform-navigation-buttons-inside-icon;
            }
            &:hover {
                background: $background-navigation-buttons-inside-hover;
                
                & .su-icon {
                    color: $color-navigation-buttons-inside-icon-hover;
                    opacity: $opacity-navigation-buttons-inside-icon-hover;
                }
            }
            &.su-right {
                border-radius: $border-radius-navigation-button-inside-right;
                box-shadow: $box-shadow-navigation-button-inside-right;
            }
        }
        &.su-outside {
            background: $background-navigation-buttons-outside;
            border: $border-navigation-buttons-outside;
            width: $width-navigation-buttons-outside;
            height: $height-navigation-buttons-outside;
            border-radius: $border-radius-navigation-buttons-outside;
            box-shadow: $box-shadow-navigation-buttons-outside;
            
            .su-icon {
                color: $color-navigation-buttons-outside-icon;
                transform: $transform-navigation-buttons-outside-icon;
            }
            &:hover {
                background: $background-navigation-buttons-outside-hover;
                
                & .su-icon {
                    color: $color-navigation-buttons-outside-icon-hover;
                    opacity: $opacity-navigation-buttons-outside-icon-hover;
                }
            }
            &.su-right {
                border-radius: $border-radius-navigation-button-outside-right;
                box-shadow: $box-shadow-navigation-button-outside-right;
            }
        }
    }
    .su-negative {
        .su-navigation-button {
            &.su-inside {
                background: $background-navigation-buttons-inside-negative;
                border: $border-navigation-buttons-inside-negative;
                box-shadow: $box-shadow-navigation-buttons-inside-negative;
                
                .su-icon {
                    color: $color-navigation-buttons-inside-icon-negative;
                }
                &:hover {
                    background: $background-navigation-buttons-inside-hover-negative;
                    
                    & .su-icon {
                        color: $color-navigation-buttons-inside-icon-hover-negative;
                    }
                }
            }
            &.su-outside {
                background: $background-navigation-buttons-outside-negative;
                border: $border-navigation-buttons-outside-negative;
                box-shadow: $box-shadow-navigation-buttons-outside-negative;
                
                .su-icon {
                    color: $color-navigation-buttons-outside-icon-negative;
                }
                &:hover {
                    background: $background-navigation-buttons-outside-hover-negative;
                    
                    & .su-icon {
                        color: $color-navigation-buttons-outside-icon-hover-negative;
                    }
                }
            }
        }
    }
}












