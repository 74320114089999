/*
    Styles du composant [card] de SipaUI
*/
@import "variables";
@import "../../Text/Titles/variables";
@import "../../Text/Sources/variables";
@import "../../Text/Date/variables";


// CARTE STANDARD
.sipaui {
    .su-card {
        border-radius: $border-radius-card;
        overflow: hidden;

        background: $background-card;
        padding: $padding-card;

        .su-media-container {
            margin: $margin-figure-card;
        }
        .su-main-picture {
            border-radius: 0;
        }
        .su-title + :is(div, p, span) {
            margin-top: $margin-top-under-title-card;
        }
    }
    .su-card { // Comportement standard d’une carte dans du flex (p. ex. su-row) : elle s’adapte en hauteur à son contenu
        align-self: flex-start;

        &.su-equal { // Comportement spécial d’une carte dans du flex (p. ex. su-row) : elle fait la même hauteur que ses voisines
            align-self: stretch;
        }
    }
    .su-carrousel-container > .su-card { // annulation quand dans un carrousel
        align-self: unset;
    }

    // COULEUR NÉGATIVE -------------------------------------/
    .su-negative {
        .su-card:not(.su-negative) {
            h2,
            h2 a,
            .su-title,
            .su-title a {
                color: $color-titles;
            }
            h2 a,
            .su-title a {
                &:hover {
                    color: $color-titles-hover;
                }
            }
            .su-source {
                color: $color-source;
            }
            .su-date {
                color: $color-date;
            }
            .su-date::before {
                background: $color-date;
            }
        }
    }

    // SPONSORISÉ -------------------------------------/
    .su-card.su-sponsored {
        background: $background-article-sponsored;
    }

    // ÉVÈNEMENT -------------------------------------/
    .su-card.su-event {
        .su-media-container {
            margin-bottom: $margin-bottom-media-container-card-event;
        }
        .su-metadata {
            margin-top:$margin-top-metadata-card-event;
        }
        .su-metadata + .su-title {
            margin-top: $margin-top-title-card-event;
        }
        .su-title + .su-standfirst {
            font-size: $font-size-standfirst-card-event;
            margin-top: $margin-top-standfirst-card-event;
        }
    }
}
