/*
    Styles du composant [helpers] de SipaUI
*/
@import "variables";

/* -- CLEARFIX -------------------------------------------------------------------- */
.su-clearfix,
.su-cf {
    @include clearfix();
}

/* -- SCROLL DOUX -------------------------------------------------------------------- */
:root:has(.su-smooth-scroll) {
    scroll-behavior: smooth;
}

/* -- HIDE -------------------------------------------------------------------- */
.su-hidden {
    display: none !important;
    visibility: hidden; // Les lecteurs d'écrans parfois ne prennent pas en compte le display none
}

// Pour cacher quelque-chose qui doit rester accessible aux lecteurs d'écrans
.su-visually-hidden { // https://developer.yahoo.com/blogs/ydn/clip-hidden-content-better-accessibility-53456.html
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    margin: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;
}
body:hover .su-visually-hidden {
    :is(a,
    input,
    button) {
        display: none !important;
    }
}

// Gestion de la visibilité en fonction des écrans touch
.su-visible-touch,
.su-visible-touch-table-cell,
.su-visible-touch-inline-block {
    display: none !important;
}

@media (hover: none) or (pointer: coarse) {
    .su-visible-touch {
        display: block !important;
    }
    .su-visible-touch-table-cell {
        display: table-cell !important;
    }
    .su-visible-touch-inline-block {
        display: inline-block !important;
    }
    .su-hidden-touch {
        display: none !important;
        visibility: hidden;
    }
}


// Gestion de la visibilité en fonction des breakpoints
@media (max-width: $max-breakpoint-xs) {
    @include responsive-invisibility('.sipaui .su-hidden-xs');
}
@media (min-width: $min-breakpoint-sm) and (max-width: $max-breakpoint-sm) {
    @include responsive-invisibility('.sipaui .su-hidden-sm');
}

@media (min-width: $min-breakpoint-md) and (max-width: $max-breakpoint-md) {
    @include responsive-invisibility('.sipaui .su-hidden-md');
}

@media (min-width: $min-breakpoint-lg) and (max-width: $max-breakpoint-lg) {
    @include responsive-invisibility('.sipaui .su-hidden-lg');
}
@media (min-width: $min-breakpoint-xl) {
    @include responsive-invisibility('.sipaui .su-hidden-xl');

}

@include responsive-invisibility('.sipaui .su-visible-xs');
@include responsive-invisibility('.sipaui .su-visible-sm');
@include responsive-invisibility('.sipaui .su-visible-md');
@include responsive-invisibility('.sipaui .su-visible-lg');
@include responsive-invisibility('.sipaui .su-visible-xl');

@include responsive-invisibility('.sipaui .su-visible-inline-block-xs');
@include responsive-invisibility('.sipaui .su-visible-inline-block-sm');
@include responsive-invisibility('.sipaui .su-visible-inline-block-md');
@include responsive-invisibility('.sipaui .su-visible-inline-block-lg');
@include responsive-invisibility('.sipaui .su-visible-inline-block-xl');

@include responsive-invisibility('.sipaui .su-visible-table-cell-xs');
@include responsive-invisibility('.sipaui .su-visible-table-cell-sm');
@include responsive-invisibility('.sipaui .su-visible-table-cell-md');
@include responsive-invisibility('.sipaui .su-visible-table-cell-lg');
@include responsive-invisibility('.sipaui .su-visible-table-cell-xl');

@media (max-width: $max-breakpoint-xs) {
    @include responsive-visibility('.sipaui .su-visible-xs');
    @include responsive-visibility('.sipaui .su-visible-inline-block-xs', inline-block);
    @include responsive-visibility('.sipaui .su-visible-table-cell-xs', table-cell);
}
@media (min-width: $min-breakpoint-sm) and (max-width: $max-breakpoint-sm) {
    @include responsive-visibility('.sipaui .su-visible-sm');
    @include responsive-visibility('.sipaui .su-visible-inline-block-sm', inline-block);
    @include responsive-visibility('.sipaui .su-visible-table-cell-sm', table-cell);
}
@media (min-width: $min-breakpoint-md) and (max-width: $max-breakpoint-md) {
    @include responsive-visibility('.sipaui .su-visible-md');
    @include responsive-visibility('.sipaui .su-visible-inline-block-md', inline-block);
    @include responsive-visibility('.sipaui .su-visible-table-cell-md', table-cell);
}
@media (min-width: $min-breakpoint-lg) and (max-width: $max-breakpoint-lg) {
    @include responsive-visibility('.sipaui .su-visible-lg');
    @include responsive-visibility('.sipaui .su-visible-inline-block-lg', inline-block);
    @include responsive-visibility('.sipaui .su-visible-table-cell-lg', table-cell);
}
@media (min-width: $min-breakpoint-xl) {
    @include responsive-visibility('.sipaui .su-visible-xl');
    @include responsive-visibility('.sipaui .su-visible-inline-block-xl', inline-block);
    @include responsive-visibility('.sipaui .su-visible-table-cell-xl', table-cell);
}



/* -- DISPLAY -------------------------------------------------------------------- */
.su-block {
    display: block !important;
}
.su-inline-block {
    display: inline-block !important;
}
.su-table {
    display: table !important;
}
.su-table-row {
    display: table-row !important;
}
.su-table-cell {
    display: table-cell !important;
}

/* -- POSITIONS -------------------------------------------------------------------- */
.su-relative {
	position: relative !important;
}
.su-absolute {
	position: absolute !important;
}
.su-fixed {
	position: fixed !important;
}

/* -- ALIGNMENT -------------------------------------------------------------------- */
.su-block-center {
    display: block !important; //utile pour forcer le centrage d’un objet non block comme les SVG
    margin-left: auto !important;
    margin-right: auto !important;
}
.su-block-left {
    display: block !important; //utile pour forcer le ferrage à gauche d’un objet non block comme les SVG
    margin-left: 0 !important;
    margin-right: auto !important;
}
.su-block-right {
    display: block !important; //utile pour forcer le ferrage à droite d’un objet non block comme les SVG
    margin-left: auto !important;
    margin-right: 0 !important;
}
.su-text-left {
    text-align: left !important;
}
.su-text-right {
    text-align: right !important;
}
.su-text-center {
    text-align: center !important;
}
.su-text-justify {
    text-align: justify !important;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens:    auto;
    -ms-hyphens:     auto;
    hyphens:         auto;
}

/* -- FULL WIDTH -------------------------------------------------------------------- */
.su-fullwidth {
    width: 100% !important;
}


/* -- FULL WIDTH MOBILE ONLY -------------------------------------------------------- */

@media only screen and (max-width: $max-breakpoint-sm) {
    .su-fullwidth-mobile {
        width: 100% !important;
    }
}

/* -- ÉTIREMENT DE BLOC MOBILE ONLY -------------------------------------------------------- */

@media only screen and (max-width: $max-breakpoint-sm) {
    .su-stretched-mobile {
        margin-left: -30px;
        margin-right: -30px;
    }
}
@media only screen and (max-width: $max-breakpoint-xs) {
    .su-stretched-mobile {
        margin-left: -20px;
        margin-right: -20px;
    }
}

/* -- COLUMNS -------------------------------------------------------- */
[class*="su-columns-"] {
    column-gap: var(--marge-m);
}
.su-columns-2 {
    columns: 2;
}
.su-columns-3 {
    columns: 3;
}
.su-columns-4 {
    columns: 4;
}

.su-columns-no-cut > * { // Empêche les enfants de couler ds plusieurs colonnes. Nécessaire pour contourner un bug de Firefox qui peut renvoyer dans la colonne suivante une marge base ou même un espace vide (un élément qui aurait un mmin-height par exemple) d’un enfant…
    display: inline-block;
    width: 100%;
}

.su-columns-min-width-200 {
    column-width: 20rem;
}
.su-columns-min-width-300 {
    column-width: 30rem;
}

@media screen and (min-width: $min-breakpoint-md) {
    .su-columns-tablet-2 {
        columns: 2;
    }
    .su-columns-tablet-3 {
        columns: 3;
    }
    .su-columns-tablet-4 {
        columns: 4;
    }
}

@media screen and (min-width: $min-breakpoint-lg) {
    .su-columns-desktop-2 {
        columns: 2;
    }
    .su-columns-desktop-3 {
        columns: 3;
    }
    .su-columns-desktop-4 {
        columns: 4;
    }
}

/* -- TEXTES -------------------------------------------------------------------------------------------------- */

.su-uppercase {
    text-transform: uppercase;
}

.su-text-nowrap, // CSS pour rétrocompatibilité à supprimer dans SipaUI 8
.su-nowrap, {
    white-space: nowrap !important;
}
.su-breakword {
    overflow-wrap: break-word !important;
}
.su-no-breakword {
    overflow-wrap: normal !important;
}
.su-hyphens {
    // -webkit-hyphens: auto;
    // -ms-hyphens: auto;
    // -moz-hyphens: auto;
    hyphens: auto;
}

[class*="su-truncate-"] {
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    overflow: hidden !important;
    white-space: normal !important; // pour surcharger le nowrap du composant Source.
}
.su-truncate-1 {
    -webkit-line-clamp: 1;
}
.su-truncate-2 {
    -webkit-line-clamp: 2;
}
.su-truncate-3 {
    -webkit-line-clamp: 3;
}
.su-truncate-4 {
    -webkit-line-clamp: 4;
}

.su-line-height-xs {
    line-height: var(--line-height-xs) !important;
}
.su-line-height-s {
    line-height: var(--line-height-s) !important;
}
.su-line-height-m {
    line-height: var(--line-height-m) !important;
}
.su-line-height-l {
    line-height: var(--line-height-l) !important;
}
.su-line-height-xl {
    line-height: var(--line-height-xl) !important;
}

/* -- AIDES STANDARDS -------------------------------------------------------------------- */
.sipaui {
    .su-standard-border {
        border: $border-helpers-standard;
    }
    .su-standard-radius {
        border-radius: $border-radius-helpers-standard;
    }
    .su-standard-padding {
        padding: $padding-helpers-standard;
    }
    .su-standard-padding-large {
        padding: $padding-helpers-standard-large;
    }
}


/* -- AIDES DIVERSES ----------------------------------------------------------------------------------------------- */
/* -- DISABLED -------------------------------------------------------------------- */
:disabled,
.su-disabled {
    opacity: var(--opacite-inactif);
    cursor: default !important;
}

/* -- NO SCROLL -------------------------------------------------------------------- */
.su-no-scroll {
    overflow: hidden;
}

/* -- NÉGATIF -------------------------------------------------------------------- */
@if $do-not-use-negative == null {
    .su-negative,
    .su-negative button,
    button.su-negative {
        color: $font-color-negative;
    }
}
