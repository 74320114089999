/*
    Styles du composant [Author] de SipaUI
*/
@import "variables";


// AUTEUR GÉNÉRIQUE-----------------------------------------------------------------------------------------/
.sipaui {
    .su-author {
        font-family: $font-family-author;
        font-size: $font-size-author;
        font-weight: $font-weight-author;
        line-height: $line-height-author;
        color: $color-author;
        font-style: normal;
    }
    .su-author a,
    a.su-author,
    .su-article .su-author a,
    .su-article a.su-author {
        &:link {
            color: $color-link-article;
            text-decoration: underline;
            border-bottom: none;
        }
        &:where(:hover, :focus, :active, :visited) {
            color: $color-link-article-hover;
        }
    }
    .su-negative {
        .su-author,
        &.su-author {
            color: $color-author-negative;
        }
    }
    .su-negative .su-author a,
    .su-negative a.su-author,
    .su-negative.su-author a,
    a.su-negative.su-author {
        &:link {
            color: $color-link-article-negative;
        }
        &:where(:hover, :focus, :active, :visited) {
            color: $color-link-article-negative-hover;
        }
    }
}
