/*
    Styles du composant [Badges] de SipaUI
*/
@import "variables";

// ICONES --------------------------------------------------------------------------------------/
.sipaui {
    .su-description {
        font-size: $font-size-description;
        color: $font-color-description;
    }
    
    // COULEUR NÉGATIVE -------------------------------------/
    &.su-negative .su-description,
    .su-negative .su-description,
    .su-description.su-negative {
        color: $font-color-description-negative;
    }
}

