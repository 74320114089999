/*
    Styles du composant [buttons] de SipaUI
*/

@import "variables";

$transition-button: .2s ease;

@mixin bouton($background, $color, $border, $box-shadow) {
    background: $background;
    color: $color;
    border: $border;
    box-shadow: $box-shadow;
}

.sipaui {
    .su-button {
        cursor: pointer;
        font-family: $font-family-button;
        font-size: $font-size-button;
        font-style: normal;
        text-align: center;
        text-decoration: none !important;
        display: inline-block;
        margin: 0;
        padding: 0 $side-padding-button;
        min-width: 120px;
        -webkit-user-select: none;
        -moz-user-select:    none;
        -ms-user-select:     none;
        user-select:         none;
        -webkit-tap-highlight-color: transparent;
        transition: background $transition-button,
                    color      $transition-button,
                    box-shadow $transition-button,
                    opacity    $transition-button;
        height: $height-button;
        line-height: $height-button;
        text-shadow: none;
        white-space: nowrap;
        //CSS pour bloquer le contenu trop long dans un tag
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        vertical-align: top; //empêche une "fausse marge" basse provoquée par l’overflow

        &.su-small {
            font-size: $font-size-button-small;
            padding: 0 $side-padding-button-small;
            height: $height-button-small;
            line-height: $height-button-small;
            min-width: 100px;

            &.su-illustration-icon {
               padding-left: calc($side-padding-button-small - 4px);
            }
            &.su-action-icon {
               padding-right: calc($side-padding-button-small - 4px);
            }
        }

        // PRIMARY ------------------------------------------------------------------------------------------/
        &.su-primary,
        &.su-primary:visited {
            @include bouton($background-button-primary,
                            $color-button-primary,
                            $border-button-primary,
                            $shadow-button-primary);
            text-transform: $text-transform-button-primary;
            font-weight: $font-weight-button-primary;
            border-radius: $border-radius-button-primary;
        }

        &.su-primary {
            &:hover:not(.su-disabled):not([disabled]),
            &:focus:not(.su-disabled):not([disabled]) {
                @include bouton($background-button-primary-hover,
                                $color-button-primary-hover,
                                $border-button-primary-hover,
                                $shadow-button-primary-hover);
                opacity: $opacity-button-primary-hover;
            }

            &:active:not(.su-disabled):not([disabled]) {
                @include bouton($background-button-primary-active,
                                $color-button-primary-active,
                                $border-button-primary-active,
                                $shadow-button-primary-active);
                opacity: $opacity-button-primary-active;
            }
        }

        // SECONDARY ------------------------------------------------------------------------------------------/
        &.su-secondary,
        &.su-secondary:visited {
            @include bouton($background-button-secondary,
                            $color-button-secondary,
                            $border-button-secondary,
                            $shadow-button-secondary);
            text-transform: $text-transform-button-secondary;
            font-weight: $font-weight-button-secondary;
            border-radius: $border-radius-button-secondary;
        }

        &.su-secondary {
            &:hover:not(.su-disabled):not([disabled]),
            &:focus:not(.su-disabled):not([disabled]) {
                @include bouton($background-button-secondary-hover,
                                $color-button-secondary-hover,
                                $border-button-secondary-hover,
                                $shadow-button-secondary-hover);
                opacity: $opacity-button-secondary-hover;
            }

            &:active:not(.su-disabled):not([disabled]) {
                @include bouton($background-button-secondary-active,
                                $color-button-secondary-active,
                                $border-button-secondary-active,
                                $shadow-button-secondary-active);
                opacity: $opacity-button-secondary-active;
            }
        }

        // NEUTRAL ------------------------------------------------------------------------------------------/
        &.su-neutral,
        &.su-neutral:visited {
            @include bouton($background-button-neutral,
                            $color-button-neutral,
                            $border-button-neutral,
                            $shadow-button-neutral);
            text-transform: $text-transform-button-neutral;
            font-weight: $font-weight-button-neutral;
            border-radius: $border-radius-button-neutral;
        }

        &.su-neutral {
            &:hover:not(.su-disabled):not([disabled]),
            &:focus:not(.su-disabled):not([disabled]) {
                @include bouton($background-button-neutral-hover,
                                $color-button-neutral-hover,
                                $border-button-neutral-hover,
                                $shadow-button-neutral-hover);
                opacity: $opacity-button-neutral-hover;
            }

            &:active:not(.su-disabled):not([disabled]) {
                @include bouton($background-button-neutral-active,
                                $color-button-neutral-active,
                                $border-button-neutral-active,
                                $shadow-button-neutral-active);
                opacity: $opacity-button-neutral-active;
            }
        }

        // SUBSCRIPTION ------------------------------------------------------------------------------------------/
        &.su-subscription,
        &.su-subscription:visited {
            @include bouton($background-button-subscription,
                            $color-button-subscription,
                            $border-button-subscription,
                            $shadow-button-subscription);
            text-transform: $text-transform-button-subscription;
            font-weight: $font-weight-button-subscription;
            border-radius: $border-radius-button-subscription;
        }

        &.su-subscription {
            &:hover:not(.su-disabled):not([disabled]),
            &:focus:not(.su-disabled):not([disabled]) {
                @include bouton($background-button-subscription-hover,
                                $color-button-subscription-hover,
                                $border-button-subscription-hover,
                                $shadow-button-subscription-hover);
                opacity: $opacity-button-subscription-hover;
            }

            &:active:not(.su-disabled):not([disabled]) {
                @include bouton($background-button-subscription-active,
                                $color-button-subscription-active,
                                $border-button-subscription-active,
                                $shadow-button-subscription-active);
                opacity: $opacity-button-subscription-active;
            }
        }

        // ICONES ------------------------------------------------------------------------------------------/
        .su-icon,
        svg,
        img {
            display: none;
        }
        svg {
            max-height: $height-icon;
        }
        &.su-illustration-icon,
        &.su-action-icon {
            .su-icon,
            svg,
            img {
                display: inline-block;
                vertical-align: middle;
                transform: translateY(-2px);
            }
        }
        &.su-illustration-icon {
                padding-left: calc($side-padding-button - 4px);

            svg,
            img {
                margin-right: 4px;
            }
        }
        &.su-action-icon {
                padding-right: calc($side-padding-button - 4px);

            svg,
            img {
                margin-left: 4px;
            }
        }

        &:not(.su-stroke) {
            svg,
            svg path {
                // fill: $color-button-primary !important;
                fill: currentColor !important;
                stroke: none !important;
            }
        }
        &.su-stroke {
            svg,
            svg path {
                // fill: $color-button-primary !important;
                fill: none !important;
                stroke: currentColor !important;
            }
        }
        &.su-primary img {
            filter: $filter-img-button-primary;
        }
        &.su-secondary img {
            filter: $filter-img-button-secondary;
        }
        &.su-subscription img {
            filter: $filter-img-button-subscription;
        }
        &.su-icon-only {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            min-width: $height-button;
            border-radius: 100%;

            &.su-small {
                min-width: $height-button-small;
            }
            .su-icon,
            svg,
            img {
                display: block;
                max-height: $height-icon;
            }
        }
    }

    // COULEUR NÉGATIVE -------------------------------------/
    &.su-negative,
    .su-negative {
        .su-button.su-primary {
            @include bouton($background-button-primary-negative,
                            $color-button-primary-negative,
                            $border-button-primary-negative,
                            $shadow-button-primary-negative);

            &:hover:not(.su-disabled):not([disabled]),
            &:focus:not(.su-disabled):not([disabled]) {
                @include bouton($background-button-primary-negative-hover,
                                $color-button-primary-negative-hover,
                                $border-button-primary-negative-hover,
                                $shadow-button-primary-negative-hover);
                opacity: $opacity-button-primary-negative-hover;
            }
            &:active:not(.su-disabled):not([disabled]) {
                @include bouton($background-button-primary-negative-active,
                                $color-button-primary-negative-active,
                                $border-button-primary-negative-active,
                                $shadow-button-primary-negative-active);
                opacity: $opacity-button-primary-negative-active;
            }
        }

        &.su-primary img {
            filter: $filter-img-button-primary-negative;
        }
    }
    .su-negative.su-button.su-primary {
        @include bouton($background-button-primary-negative,
                        $color-button-primary-negative,
                        $border-button-primary-negative,
                        $shadow-button-primary-negative);

        &:hover:not(.su-disabled):not([disabled]),
        &:focus:not(.su-disabled):not([disabled]) {
            @include bouton($background-button-primary-negative-hover,
                            $color-button-primary-negative-hover,
                            $border-button-primary-negative-hover,
                            $shadow-button-primary-negative-hover);
            opacity: $opacity-button-primary-negative-hover;
        }
        &:active:not(.su-disabled):not([disabled]) {
            @include bouton($background-button-primary-negative-active,
                            $color-button-primary-negative-active,
                            $border-button-primary-negative-active,
                            $shadow-button-primary-negative-active);
            opacity: $opacity-button-primary-negative-active;
        }
        img {
            filter: $filter-img-button-primary-negative;
        }
    }

    &.su-negative,
    .su-negative {
        .su-button.su-secondary {
            @include bouton($background-button-secondary-negative,
                            $color-button-secondary-negative,
                            $border-button-secondary-negative,
                            $shadow-button-secondary-negative);

            &:hover:not(.su-disabled):not([disabled]),
            &:focus:not(.su-disabled):not([disabled]) {
                @include bouton($background-button-secondary-negative-hover,
                                $color-button-secondary-negative-hover,
                                $border-button-secondary-negative-hover,
                                $shadow-button-secondary-negative-hover);
                opacity: $opacity-button-secondary-negative-hover;
            }
            &:active:not(.su-disabled):not([disabled]) {
                @include bouton($background-button-secondary-negative-active,
                                $color-button-secondary-negative-active,
                                $border-button-secondary-negative-active,
                                $shadow-button-secondary-negative-active);
                opacity: $opacity-button-secondary-negative-active;
            }

        }

        &.su-secondary img {
            filter: $filter-img-button-secondary-negative;
        }
    }
    .su-negative.su-button.su-secondary {
        @include bouton($background-button-secondary-negative,
                        $color-button-secondary-negative,
                        $border-button-secondary-negative,
                        $shadow-button-secondary-negative);

        &:hover:not(.su-disabled):not([disabled]),
        &:focus:not(.su-disabled):not([disabled]) {
            @include bouton($background-button-secondary-negative-hover,
                            $color-button-secondary-negative-hover,
                            $border-button-secondary-negative-hover,
                            $shadow-button-secondary-negative-hover);
            opacity: $opacity-button-secondary-negative-hover;
        }
        &:active:not(.su-disabled):not([disabled]) {
            @include bouton($background-button-secondary-negative-active,
                            $color-button-secondary-negative-active,
                            $border-button-secondary-negative-active,
                            $shadow-button-secondary-negative-active);
            opacity: $opacity-button-secondary-negative-active;
        }
        img {
            filter: $filter-img-button-secondary-negative;
        }
    }

    &.su-negative,
    .su-negative {
        .su-button.su-neutral {
            @include bouton($background-button-neutral-negative,
                            $color-button-neutral-negative,
                            $border-button-neutral-negative,
                            $shadow-button-neutral-negative);

            &:hover:not(.su-disabled):not([disabled]),
            &:focus:not(.su-disabled):not([disabled]) {
                @include bouton($background-button-neutral-negative-hover,
                                $color-button-neutral-negative-hover,
                                $border-button-neutral-negative-hover,
                                $shadow-button-neutral-negative-hover);
                opacity: $opacity-button-neutral-negative-hover;
            }
            &:active:not(.su-disabled):not([disabled]) {
                @include bouton($background-button-neutral-negative-active,
                                $color-button-neutral-negative-active,
                                $border-button-neutral-negative-active,
                                $shadow-button-neutral-negative-active);
                opacity: $opacity-button-neutral-negative-active;
            }
        }
    }
    .su-negative.su-button.su-neutral {
        @include bouton($background-button-neutral-negative,
                        $color-button-neutral-negative,
                        $border-button-neutral-negative,
                        $shadow-button-neutral-negative);

        &:hover:not(.su-disabled):not([disabled]),
        &:focus:not(.su-disabled):not([disabled]) {
            @include bouton($background-button-neutral-negative-hover,
                            $color-button-neutral-negative-hover,
                            $border-button-neutral-negative-hover,
                            $shadow-button-neutral-negative-hover);
            opacity: $opacity-button-neutral-negative-hover;
        }
        &:active:not(.su-disabled):not([disabled]) {
            @include bouton($background-button-neutral-negative-active,
                            $color-button-neutral-negative-active,
                            $border-button-neutral-negative-active,
                            $shadow-button-neutral-negative-active);
            opacity: $opacity-button-neutral-negative-active;
        }
    }
}
