/*
    Styles du composant [Toasts] de SipaUI
*/
@import "variables";

// Animation d’ouverture
@keyframes animation-toasts {
  0%{
    bottom: $bottom-toasts-debut;
  }
  100%{
    bottom: $bottom-toasts-final;
  }
}

.sipaui {
    .su-toast {
        flex-direction: column;
        position: fixed;
        overflow: hidden;
        margin: 0;
        top: $top-toasts;
        right: $right-toasts;
        bottom: $bottom-toasts-debut;
        left: $left-toasts;
        width: $width-toasts;
        max-width: $max-width-toasts;
        max-height: $max-height-toasts;
        backdrop-filter: $backdrop-filter-toasts;
        background: $background-toasts;
        border: $border-toasts;
        border-radius: $border-radius-toasts;
        padding: $padding-toasts;
        color: $color-texte-toasts;
        text-align: $text-align-texte-toasts;
        z-index: 10900;
        
        * {
            color: $color-texte-toasts;
        }
        > :first-child {
            margin-top: 0;
        }
        > :last-child {
            margin-bottom: 0;
        }
        &.su-open,
        &[open]  {
            display: inline-flex;
            animation: $animation-toasts;
        }
    }
}
