/*
    Styles du composant [Input Group] de SipaUI
*/
@import "variables";

.sipaui {
    input[type=file] {
        cursor: pointer;
        width: 100%;
        font-size: $font-size-input-file;
        color: $color-input-file;

        &::file-selector-button {
            min-width: 100px;
            font-size: $font-size-input-file-button;
            margin: $margin-input-file-button;
            padding: $padding-input-file-button;
            height: $height-input-file-button;
            line-height: $height-input-file-button;
            @include bouton($background-input-file-button,
                            $color-input-file-button,
                            $border-input-file-button,
                            $shadow-input-file-button);
            text-transform: $text-transform-input-file-button;
            font-weight: $font-weight-input-file-button;
            border-radius: $border-radius-input-file-button;
        }
        &:hover::file-selector-button {
            opacity: $opacity-hover;
        }
    }

    &.su-negative input[type=file],
    .su-negative input[type=file],
    input[type=file].su-negative {
        color: $color-input-file-negative;

        &::file-selector-button {
            @include bouton($background-input-file-button-negative,
                            $color-input-file-button-negative,
                            $border-input-file-button-negative,
                            $shadow-input-file-button-negative);
        }
    }
}
