/*
    Styles du composant [picture] de SipaUI
*/
@import "variables";

// CŒUR ARTICLE --------------------------------------------------------------------------------------/
.sipaui {
    .su-article {
        figure {
            margin: $margin-figure-article;

            &:first-child {
                margin-top: 0;
            }
            &:last-child {
                margin-bottom: 0;
            }
            .su-media,
            img,
            picture {
                width: 100%;
                border-radius: $border-radius-img-article; // pour gérer tout ce qui peut éventuellement être inclus dans un su-meta
            }
            img,
            picture {
                box-sizing: border-box;
                border: $border-img-article;
                box-shadow: $box-shadow-img-article;
            }
        }
    }
}

@media screen and (max-width: $max-breakpoint-sm) {
    .sipaui {
        .su-article {
            figure.su-edge-mobile {
                margin-left: calc(#{$marge-container-sm} * -1);
                margin-right: calc(#{$marge-container-sm} * -1);

                :is(img,
                picture,
                video,
                iframe,
                .su-main-picture,
                .su-media) {
                    border-radius: 0;
                }
            }
        }
    }
}

@media screen and (max-width: $max-breakpoint-xs) {
    .sipaui {
        .su-article {
            figure.su-edge-mobile {
                margin-left: calc(#{$marge-container-xs} * -1);
                margin-right: calc(#{$marge-container-xs} * -1);
            }
        }
    }
}

// MÉDIA CONTENEUR --------------------------------------------------------------------------------------/
.sipaui {
    .su-media-container {
        position: relative;
    }
}

// MAIN PICTURE --------------------------------------------------------------------------------------/
.sipaui {
    .su-main-picture {
        box-sizing: border-box;
        border-radius: $border-radius-img-main-picture;
        border-width: $border-width-img-main-picture;
        border-color: $border-color-img-main-picture;
        border-style: $border-style-img-main-picture;
        box-shadow: $box-shadow-img-main-picture;
        position: relative;
        overflow: hidden;
        width: fit-content;
    }
}

// RATIOS --------------------------------------------------------------------------------------/
.sipaui {
    [class*="su-ratio-"] {
        position: relative;
        overflow: hidden;
        width: auto; //sert à contrer le width: fit-content de su-main-picture

        & .su-media {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            height: 100%;
            width: 100%;
            min-width: 100%; // Pour réserver la pale pour les images lazy-loadées
            object-fit: cover;
        }
    }

    .su-ratio-16-7 {
        padding-top: calc(43.75% - (#{$border-width-img-main-picture} * 2));
    }
    .su-ratio-16-9 {
        padding-top: calc(56.25% - (#{$border-width-img-main-picture} * 2));
    }
    .su-ratio-4-3 {
        padding-top: calc(75% - (#{$border-width-img-main-picture} * 2));
    }
    .su-ratio-1-1 {
        padding-top: 100%;
    }
}

@media screen and (min-width: $min-breakpoint-md) {
    .sipaui {
        .su-ratio-16-7-md {
            padding-top: calc(43.75% - (#{$border-width-img-main-picture} * 2));
        }
        .su-ratio-16-9-md {
            padding-top: calc(56.25% - (#{$border-width-img-main-picture} * 2));
        }
        .su-ratio-4-3-md {
            padding-top: calc(75% - (#{$border-width-img-main-picture} * 2));
        }
        .su-ratio-1-1-md {
            padding-top: 100%;
        }
    }
}

@media screen and (min-width: $min-breakpoint-lg) {
    .sipaui {
        .su-ratio-16-7-lg {
            padding-top: calc(43.75% - (#{$border-width-img-main-picture} * 2));
        }
        .su-ratio-16-9-lg {
            padding-top: calc(56.25% - (#{$border-width-img-main-picture} * 2));
        }
        .su-ratio-4-3-lg {
            padding-top: calc(75% - (#{$border-width-img-main-picture} * 2));
        }
        .su-ratio-1-1-lg {
            padding-top: 100%;
        }
    }
}


// MODE PORTRAIT --------------------------------------------------------------------------------------/
.sipaui {
    .su-portrait {
        &.su-ratio-16-7 {
            padding-top: calc(228.57% - (#{$border-width-img-main-picture} * 2));
        }
        &.su-ratio-16-9 {
            padding-top: calc(177.78% - (#{$border-width-img-main-picture} * 2));
        }
        &.su-ratio-4-3 {
            padding-top: calc(133.33% - (#{$border-width-img-main-picture} * 2));
        }
    }
}

@media screen and (min-width: $min-breakpoint-md) {
    .sipaui {
        .su-portrait {
            &.su-ratio-16-7-md {
                padding-top: calc(228.57% - (#{$border-width-img-main-picture} * 2));
            }
            &.su-ratio-16-9-md {
                padding-top: calc(177.78% - (#{$border-width-img-main-picture} * 2));
            }
            &.su-ratio-4-3-md {
                padding-top: calc(133.33% - (#{$border-width-img-main-picture} * 2));
            }
        }
    }
}

@media screen and (min-width: $min-breakpoint-lg) {
    .sipaui {
        .su-portrait {
            &.su-ratio-16-7-lg {
                padding-top: calc(228.57% - (#{$border-width-img-main-picture} * 2));
            }
            &.su-ratio-16-9-lg {
                padding-top: calc(177.78% - (#{$border-width-img-main-picture} * 2));
            }
            &.su-ratio-4-3-lg {
                padding-top: calc(133.33% - (#{$border-width-img-main-picture} * 2));
            }
        }
    }
}


// RADIUS OPTIONNEL --------------------------------------------------------------------------------------/
.sipaui {
    .su-radius-on,
    figure > .su-radius-on,
    .su-main-picture.su-radius-on {
        border-radius: $border-radius-on !important;
        overflow: hidden;
    }
    .su-radius-off,
    figure > .su-radius-off,
    .su-main-picture.su-radius-off {
        border-radius: $border-radius-off !important;
    }
}

// IMAGE NON RECADRÉE --------------------------------------------------------------------------------------/
.sipaui {
    .su-no-crop {
        .su-media {
            height: 100%;
            object-fit: contain;
            min-height: 100%;
            min-width: 100%;
            display: block;
        }
        .su-background {
            background-position: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            height: 100%;
            width: 100%;
            z-index: -1; // CSS pour rétrocompatibilité à supprimer dans SipaUI 8 ? (inutile si on respecte bien l’ordre du code, image floue avant l’image)
            filter: $filter-background-no-crop;
        }
    }
}

