/*
    Styles du composant pagination de SipaUI
    Utilise les variables à la fois globales et du composant
*/
@import "variables";

$largeur-bouton-premier-dernier: 50px;
$largeur-bouton-precedent-suivant: var(--taille-tactile);
$largeur-bouton-select: 72px;
$marges-boutons: var(--marge-s);
$largeur-max-composant: calc(#{$largeur-bouton-premier-dernier} * 2 + #{$largeur-bouton-precedent-suivant} * 2 + #{$largeur-bouton-select} + #{$marges-boutons} * 4);



// PAGINATION BASE --------------------------------------------------------------------------------------/
.sipaui {
    .su-pagination {
        > ul {
            display: flex;
            gap: $marges-boutons;
            max-width: $largeur-max-composant;
            margin: 0 auto;
            position: relative;
            z-index: 2;
            padding: 0;
            
            > li {
                height: var(--taille-tactile);
                width: $largeur-bouton-precedent-suivant;
                background: $background-page-item;
                border: $border-type-page-item $border-color-page-item;
                border-radius: $border-radius-page-item;
                
                &:first-child,
                &:last-child {
                    width: $largeur-bouton-premier-dernier;
                }
                &:hover {
                    border-color: $border-color-page-item-hover;
                }
                > a,
                > span,
                > button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    width: 100%;
                }
                &,
                a,
                span,
                button {
                    color: $color-page-item;
                    font-weight: $font-weight-page-item;
                }
            }
            li:hover > a {
                color: $color-page-item-hover;
            }
            
        }
        &.su-first-page {
            > ul >:nth-child(-n+2) {
                opacity: var(--opacite-inactif);
                
                &:hover {
                    border: $border-type-page-item $border-color-page-item;
                    
                    a { // pour le cas où le dev ne transformerait pas le <a> en <span>
                        color: $color-page-item;
                        cursor: default;
                        pointer-events: none;
                    }
                }
            }
        }
        &.su-last-page {
            > ul >:nth-last-child(-n+2) {
                opacity: var(--opacite-inactif);
                
                &:hover {
                    border: $border-type-page-item $border-color-page-item;
                    
                    a { // pour le cas où le dev ne transformerait pas le <a> en <span>
                        color: $color-page-item;
                        cursor: default;
                        pointer-events: none;
                    }
                }
            }
        }
        .su-page-active {
            font-weight: $font-weight-page-active;
            background: $background-page-active;
            color: $color-page-active;
        }
    }
    
    // COULEUR NÉGATIVE -------------------------------------/
    .su-negative .su-pagination,
    .su-pagination.su-negative {
        > ul > li {
            border-color: $border-color-page-item-negative;
            
            &:hover {
                border-color: $border-color-page-item-hover-negative;
            }
            > a,
            > span,
            > button {
                color: $color-page-item-negative;
            }
            &:hover > a {
                color: $color-page-item-hover-negative;
            }
        }
        &.su-first-page {
            > ul >:nth-child(-n+2) {
                &:hover {
                    border-color: $border-color-page-item-negative;
                    
                    a { // pour le cas où le dev ne transformerait pas le <a> en <span>
                        color: $color-page-item-negative;
                    }
                }
            }
        }
        &.su-last-page {
            > ul >:nth-last-child(-n+2) {
                &:hover {
                    border-color: $border-color-page-item-negative;
                    
                    a { // pour le cas où le dev ne transformerait pas le <a> en <span>
                        color: $color-page-item-negative;
                    }
                }
            }
        }
        .su-pages-list {
            a {
                color: $color-page-item;
            }
            li:hover > a {
                color: $color-page-item-hover;
            }
        }   
    }
}


// PAGINATION RICHE --------------------------------------------------------------------------------------/
.sipaui {
    .su-pagination {
        .su-page-select {
            width: $largeur-bouton-select;
            border: $border-page-select;
            background: $background-page-select;
    
            > button {
                cursor: pointer;
                color: $color-page-select;
                font-weight: $font-weight-page-select;
    
                &::after {
                    content: "\e901";
                    display: inline-block;
                    font-family: su-icons;
                    font-size: 19px;
                    font-weight: var(--font-weight-normal);
                    line-height: 1;
                    margin-left: 3px;
                    height: 18px;
                    width: 18px;
                    transition: transform .1s ease-out;
                }
            }
            &:hover {
                border-color: $border-color-page-item-hover;
    
                > button {
                    color: $color-page-item-hover;
                }
            }
        }
        &.su-first-page.su-last-page {
            .su-page-select {
                border-color: $border-color-page-item;
                
                > button {
                    cursor: default;
                    color: $color-page-select;
                    
                    &::after {
                        display: none;
                    }
                }
            }
        }
        .su-pages-list {
            max-height: 0;
            overflow: hidden;
            width: 300px;
            position: relative;
            top: 1px;
            left: 50%;
            transform: translateX(-50%);
            background: $background-gradient-pages-list; /* dégradé bas quand on est en haut*/
            background-color: $background-color-pages-list;
            background-repeat: no-repeat;
            background-size: 100% $background-gradient-height-pages-list;
            background-position-y: calc(#{$max-height-pages-list} - #{$background-gradient-height-pages-list});
        	background-attachment: scroll;
            
            > li {
                height: $height-pages-list-item;
                display: flex;
                justify-content: center;
                align-items: center;
                border-bottom: $border-bottom-pages-list-item;
                
                &:last-child {
                        background: $background-color-pages-list;
                    }                
            }
            .su-separator {
                border-bottom-width: $border-bottom-width-pages-list-item-10;
            }
            a { 
                width: 100%;
                text-align: center;
            }
        }
        .su-close {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            -webkit-appearance: none; /* utile pour un bug Safari mobile */
            display: none;
            opacity: 0;
            z-index: 1; /* utile pour passer au-dessus des éléments plus bas dans le DOM */
        }
        &.su-pages-list-in {
            .su-page-select {
                border: $border-page-select-in;
                background: $background-page-select-in;
                border-bottom: $border-bottom-page-select-in;
                border-radius: $border-radius-page-item $border-radius-page-item 0 0 ;
                
                > button {
                    &::after {
                        transform: rotate(180deg);
                        transition: transform .3s ease-out;
                    }
                }
            }
            .su-pages-list {
                max-height: $max-height-pages-list;
                transition: max-height .5s ease-out;
                overflow-y: auto;
                z-index: 100;            
            }
            .su-close {
                display: block
            }
        }
        &.su-last-page {
            .su-page-select {
                //pointer-events: none;
                //cursor: default;
                //border: $border-type-page-item rgba($border-color-page-item, var(--opacite-inactif));
                > button {
                    
                    &::after {
                        //opacity: var(--opacite-inactif); 
                    }
                }
            }
        }
    }
    
    // SOMMAIRE --------------------------------------------------------------------------------------/
    .su-pagination.su-summary {
        > ul {
            max-width: none;
            justify-content: space-between;
            
            > li {
                &:first-child,
                &:last-child {
                    width: $largeur-bouton-precedent-suivant;
                }
            }
        }
        .su-page-select {
            flex: 1;
        }
        .su-pages-list {
            width: auto;
        }
    }
    
    
    // COULEUR NÉGATIVE -------------------------------------/
    .su-negative .su-pagination,
    .su-pagination.su-negative {
        .su-page-select {
            > button {
                color: $color-page-select-negative;
            }
            &:hover {
                > button {
                    color: $color-page-item-hover-negative;
                }
            }
        }
        &.su-pages-list-in {
            .su-page-select {
                border: $border-page-select-in-negative;
                background: $background-page-select-in-negative;
                border-bottom: $border-bottom-page-select-in-negative;
                
                > button {
                    color: $color-page-select-in-negative;
                    
                    &:hover {
                        color: $color-page-select-in-hover-negative;
                    }
                }
            }
        }
        &.su-last-page {
            .su-page-select {
                border-color: rgba($border-color-page-item-negative, var(--opacite-inactif));
                > button {
                    &::after {
                        opacity: var(--opacite-inactif); 
                    }
                }
            }
        }
    }
}


@media screen and (max-width: $max-breakpoint-xs) {
	.sipaui {
        .su-pagination {
            > ul > li {
                margin-right: var(--marge-xs);
            }
            .su-pages-list {
                width: 280px;
            }
        }
    }
}

