/*
    Styles du composant [social-buttons] de SipaUI
*/
@import "variables";

// ICONES --------------------------------------------------------------------------------------/
.sipaui {
    .su-social-button {
        display: inline-flex;
        align-items: center;
        padding: 0; // Pour surcharger les styles de bouton par défaut
        color: $font-color-social-button;

        .su-icon {
            height: $size-touch-sm;
            width: $size-touch-sm;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            color: $font-color-social-button;
            padding-bottom: $padding-social-button; // pour recentrer verticalement

            & + span {
                margin-left: $gap-name-social-button;
            }
        }

        &:hover,
        &:focus,
        &:active {
            color: $font-color-social-button-hover;
            opacity: $opacity-hover;
        }
        &:is(.su-facebook,
        .su-whatsapp,
        .su-x,
        .su-bluesky,
        .su-instagram,
        .su-flipboard,
        .su-pinterest,
        .su-linkedin,
        .su-spotify,
        .su-deezer,
        .su-apple-podcast,
        .su-amazon-music,
        .su-youtube-music,
        .su-rss,
        .su-share,
        .su-mobile-app,
        .su-mail) {
            .su-icon {
                color: $color-social-button-with-background;
                background: $background-social-button-with-background;
                border-radius: $border-radius-social-button-with-background;
                font-size: $font-size-social-button-with-background;
            }
        }
        &.su-facebook {
            .su-icon {
                background: var(--facebook)
            }
        }
        &.su-whatsapp {
            .su-icon {
                background: var(--whatsapp)
            }
        }
        &.su-bluesky {
            .su-icon {
                background: var(--bluesky)
            }
        }
        &.su-x {
            .su-icon {
                background: var(--x)
            }
        }
        &.su-instagram {
            .su-icon {
                background: var(--instagram)
            }
        }
        &.su-flipboard {
            .su-icon {
                background: var(--flipboard)
            }
        }
        &.su-pinterest {
            .su-icon {
                background: var(--pinterest)
            }
        }
        &.su-linkedin {
            .su-icon {
                background: var(--linkedin)
            }
        }
        &.su-spotify {
            .su-icon {
                background: var(--spotify)
            }
        }
        &.su-deezer {
            .su-icon {
                background: var(--deezer)
            }
        }
        &.su-amazon-music {
            .su-icon {
                background: var(--amazon-music)
            }
        }
        &.su-apple-podcast {
            .su-icon {
                background: var(--apple-podcast)
            }
        }
        &.su-youtube-music {
            .su-icon {
                background: var(--youtube)
            }
        }
        &.su-rss {
            .su-icon {
                background: var(--fluxrss)
            }
        }

        // Big
        &.su-big {
            .su-icon {
                height: $size-touch;
                width: $size-touch;
            }
            &:is(.su-facebook,
            .su-x,
            .su-instagram,
            .su-flipboard,
            .su-linkedin,
            .su-spotify,
            .su-deezer,
            .su-apple-podcast,
            .su-amazon-music,
            .su-youtube-music,
            .su-rss,
            .su-share,
            .su-mobile-app,
            .su-mail) {
                .su-icon {
                    font-size: $font-size-social-button-with-background-big;
                }
            }
        }
    }
    .su-negative .su-social-button,
    .su-social-button.su-negative {
        .su-icon {
            color: $font-color-negative;
        }
    }
}
