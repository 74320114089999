/*
    Styles du composant [sources] de SipaUI
*/
@import "variables";

// COCARDES
/*
Par défaut: on cherche sur le CDN;

mais pour travailler en local il suffit de faire
$url-cocardes: '/FRAMEWORK/2COMPOSANTS/Text/Sources/images/';

*/
$url-cocardes: 'https://cdn.sipaof.fr/sipaui/latest/downloads/cocardes/' !default;
// $url-cocardes: '/downloads/cocardes/'; // version pour dev en local

.sipaui {
    [class*="su-cockade-"] {
        background: no-repeat;
        background-size: 100%;
        display: inline-block;
        min-height: $taille-cocarde;
        min-width: $taille-cocarde;
    }
    .su-cockade-20m {
        background-image: url('#{$url-cocardes}20m.svg');
    }
    .su-cockade-5p {
        background-image: url('#{$url-cocardes}5p.svg');
    }
    .su-cockade-a {
        background-image: url('#{$url-cocardes}a.svg');
    }
    .su-cockade-af {
        background-image: url('#{$url-cocardes}af.svg');
    }
    .su-cockade-amt {
        background-image: url('#{$url-cocardes}amt.svg');
    }
    .su-cockade-api {
        background-image: url('#{$url-cocardes}api.svg');
    }
    .su-cockade-b {
        background-image: url('#{$url-cocardes}b.svg');
    }
    .su-cockade-bcd {
        background-image: url('#{$url-cocardes}bcd.svg');
    }
    .su-cockade-bec {
        background-image: url('#{$url-cocardes}bec.svg');
    }
    .su-cockade-bjkc {
        background-image: url('#{$url-cocardes}bjkc.svg');
    }
    .su-cockade-bm {
        background-image: url('#{$url-cocardes}bm.svg');
    }
    .su-cockade-bp {
        background-image: url('#{$url-cocardes}bp.svg');
    }
    .su-cockade-ca {
        background-image: url('#{$url-cocardes}ca.svg');
    }
    .su-cockade-cd {
        background-image: url('#{$url-cocardes}cd.svg');
    }
    .su-cockade-cdp {
        background-image: url('#{$url-cocardes}cdp.svg');
    }
    .su-cockade-clr {
        background-image: url('#{$url-cocardes}clr.svg');
    }
    .su-cockade-cm {
        background-image: url('#{$url-cocardes}cm.svg');
    }
    .su-cockade-co {
        background-image: url('#{$url-cocardes}co.svg');
    }
    .su-cockade-cp {
        background-image: url('#{$url-cocardes}cp.svg');
    }
    .su-cockade-cr {
        background-image: url('#{$url-cocardes}cr.svg');
    }
    .su-cockade-d {
        background-image: url('#{$url-cocardes}d.svg');
    }
    .su-cockade-e {
        background-image: url('#{$url-cocardes}e.svg');
    }
    .su-cockade-eo {
        background-image: url('#{$url-cocardes}eo.svg');
    }
    .su-cockade-eof {
        background-image: url('#{$url-cocardes}eof.svg');
    }
    .su-cockade-f1o {
        background-image: url('#{$url-cocardes}f1o.svg');
    }
    .su-cockade-fa {
        background-image: url('#{$url-cocardes}fa.svg');
    }
    .su-cockade-fff {
        background-image: url('#{$url-cocardes}fff.svg');
    }
    .su-cockade-ffv {
        background-image: url('#{$url-cocardes}ffv.svg');
    }
    .su-cockade-fl {
        background-image: url('#{$url-cocardes}fl.svg');
    }
    .su-cockade-gi {
        background-image: url('#{$url-cocardes}gi.svg');
    }
    .su-cockade-hw {
        background-image: url('#{$url-cocardes}hw.svg');
    }
    .su-cockade-i {
        background-image: url('#{$url-cocardes}i.svg');
    }
    .su-cockade-jdl {
        background-image: url('#{$url-cocardes}jdl.svg');
    }
    .su-cockade-jj {
        background-image: url('#{$url-cocardes}jj.svg');
    }
    .su-cockade-jsp {
        background-image: url('#{$url-cocardes}jsp.svg');
    }
    .su-cockade-k {
        background-image: url('#{$url-cocardes}k.svg');
    }
    .su-cockade-kl {
        background-image: url('#{$url-cocardes}kl.svg');
    }
    .su-cockade-ks {
        background-image: url('#{$url-cocardes}ks.svg');
    }
    .su-cockade-le {
        background-image: url('#{$url-cocardes}le.svg');
    }
    .su-cockade-lm {
        background-image: url('#{$url-cocardes}lm.svg');
    }
    .su-cockade-lmag {
        background-image: url('#{$url-cocardes}lmag.svg');
    }
    .su-cockade-lp {
        background-image: url('#{$url-cocardes}lp.svg');
    }
    .su-cockade-mce {
        background-image: url('#{$url-cocardes}mce.svg');
    }
    .su-cockade-mda {
        background-image: url('#{$url-cocardes}mda.svg');
    }
    .su-cockade-mdchat {
        background-image: url('#{$url-cocardes}mdchat.svg');
    }
    .su-cockade-mdchien {
        background-image: url('#{$url-cocardes}mdchien.svg');
    }
    .su-cockade-mdlc {
        background-image: url('#{$url-cocardes}mdlc.svg');
    }
    .su-cockade-mds {
        background-image: url('#{$url-cocardes}mds.svg');
    }
    .su-cockade-mif {
        background-image: url('#{$url-cocardes}mif.svg');
    }
    .su-cockade-ml {
        background-image: url('#{$url-cocardes}ml.svg');
    }
    .su-cockade-msa {
        background-image: url('#{$url-cocardes}msa.svg');
    }
    .su-cockade-o {
        background-image: url('#{$url-cocardes}o.svg');
    }
    .su-cockade-oe {
        background-image: url('#{$url-cocardes}oe.svg');
    }
    .su-cockade-of {
        background-image: url('#{$url-cocardes}of.svg');
    }
    .su-cockade-or {
        background-image: url('#{$url-cocardes}or.svg');
    }
    .su-cockade-p {
        background-image: url('#{$url-cocardes}p.svg');
    }
    .su-cockade-pb {
        background-image: url('#{$url-cocardes}pb.svg');
    }
    .su-cockade-pdld {
        background-image: url('#{$url-cocardes}pdld.svg');
    }
    .su-cockade-ph {
        background-image: url('#{$url-cocardes}ph.svg');
    }
    .su-cockade-pm {
        background-image: url('#{$url-cocardes}pm.svg');
    }
    .su-cockade-po {
        background-image: url('#{$url-cocardes}po.svg');
    }
    .su-cockade-qp {
        background-image: url('#{$url-cocardes}qp.svg');
    }
    .su-cockade-sen {
        background-image: url('#{$url-cocardes}sen.svg');
    }
    .su-cockade-sef {
        background-image: url('#{$url-cocardes}sef.svg');
    }
    .su-cockade-sp {
        background-image: url('#{$url-cocardes}sp.svg');
    }
    .su-cockade-ta {
        background-image: url('#{$url-cocardes}ta.svg');
    }
    .su-cockade-tam {
        background-image: url('#{$url-cocardes}tam.svg');
    }
    .su-cockade-tc {
        background-image: url('#{$url-cocardes}tc.svg');
    }
    .su-cockade-tdj {
        background-image: url('#{$url-cocardes}tdj.svg');
    }
    .su-cockade-tm {
        background-image: url('#{$url-cocardes}tm.svg');
    }
    .su-cockade-tn {
        background-image: url('#{$url-cocardes}tn.svg');
    }
    .su-cockade-tr {
        background-image: url('#{$url-cocardes}tr.svg');
    }
    .su-cockade-vao {
        background-image: url('#{$url-cocardes}vao.svg');
    }
    .su-cockade-vb {
        background-image: url('#{$url-cocardes}vb.svg');
    }
    .su-cockade-vpc {
        background-image: url('#{$url-cocardes}vpc.svg');
    }
    .su-cockade-vv {
        background-image: url('#{$url-cocardes}vv.svg');
    }
    .su-cockade-w {
        background-image: url('#{$url-cocardes}w.svg');
    }
    .su-cockade-wtc {
        background-image: url('#{$url-cocardes}wtc.svg');
    }
    .su-cockade-wtt {
        background-image: url('#{$url-cocardes}wtt.svg');
    }
}



// SOURCE
.sipaui {
    .su-source {
        font-size: $font-size-source;
        color: $color-source;
        line-height: $line-height-source;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &[class*="su-cockade-"] {
            background-size: $taille-cocarde;
            background-position: 0;
            padding-left: calc(#{$taille-cocarde} + #{$marge-cocarde});
        }
    }

    a.su-source,
    .su-source a,
    .su-article a.su-source,
    .su-article .su-source a {
        border-bottom: none !important;
        color: $color-lien-source;

        // &:hover {
        //     color: $color-lien-source-hover;
        //     opacity: 1;
        // }
    }

    .su-articles-list,
    .su-articles-area {
        .su-source {
            &[aria-description]:empty:after {
                content: attr(aria-description);
            }
            &:empty:not([aria-description]) {
                padding: 0;
            }
            &[class*="su-cockade-"] {
                background-size: $taille-cocarde-liste;
                background-position: 0;
                padding-left: calc(#{$taille-cocarde-liste} + #{$marge-cocarde});
            }
        }
    }
    .su-article {
        .su-source {
            font-size: $font-size-source-edito;
        }
    }

    // COULEUR NÉGATIVE -------------------------------------/
    .su-negative .su-source,
    .su-negative.su-source {
        color: $color-source-negative;
    }
    .su-negative a.su-source,
    .su-negative .su-source a,
    .su-negative .su-article a.su-source,
    .su-negative .su-article .su-source a,
    .su-negative.su-article a.su-source,
    .su-negative.su-article .su-source a {
        color: $color-lien-source-negative;

        // &:hover {
        //     color: $color-lien-source-negative-hover;
        // }
    }
}
