/*
    Styles du composant [Podcast] de SipaUI
*/
@import "variables";

.sipaui {
    .su-podcast {
        // .su-progress-bar {
        //                                                                             $padding-top-progress-bar-podcast: calc($border-radius-img-main-picture - $height-progress-bar);
        //                                                                             border-radius: 0 0 $border-radius-img-main-picture $border-radius-img-main-picture;
        //                                                                             padding-top: $padding-top-progress-bar-podcast;
        //                                                                             height: $border-radius-img-main-picture;
        //                                                                             clip-path: inset($padding-top-progress-bar-podcast 0 0 0);
        //     position: absolute;
        //     bottom: 0;
        // }
        position: relative;

        &:has(.su-button) .su-title a::before {
            bottom: calc(var(--taille-tactile) + $margin-top-play-pause-podcast);
        }
        &:has(.su-button.su-small) .su-title a::before {
            bottom: calc(var(--taille-tactile-mini) + $margin-top-play-pause-podcast);
        }
        .su-title.su-truncate-1 {
            height: $height-title-1line-podcast;
        }
        .su-title.su-truncate-2 {
            height: $height-title-2lines-podcast;
        }
        .su-title.su-truncate-3 {
            height: $height-title-3lines-podcast;
        }
        .su-title.su-truncate-4 {
            height: $height-title-4lines-podcast;
        }
        .su-channel + .su-title {
            margin-top: $margin-top-title-after-channel-podcast;
        }
        .su-metadata {
            margin-top: $margin-top-metadata-podcast;
        }
        .su-play-pause {
            margin-top: $margin-top-play-pause-podcast;
        }
    }

    .su-podcast.su-teaser:has(.su-channel) {
            grid-template-rows: $grid-template-rows-podcast-teaser;
            //gap: $gap-podcast-teaser;

        .su-media-container {
            grid-area: $grid-area-media-container-podcast-teaser;
        }
        .su-channel {
            grid-area: $grid-area-channel-podcast-teaser;
        }
        .su-title {
            grid-area: $grid-area-title-podcast-teaser;
            margin-top: $margin-top-title-podcast-teaser;
        }
        .su-metadata {
            grid-area: $grid-area-metadata-podcast-teaser;
        }
        // .su-progress-bar {
        //     bottom: unset;
        //     transform: translateY(-100%);
        // }
    }
    .su-podcast.su-teaser {
        .su-metadata {
            margin: 0;
        }
        .su-play-pause {
            margin-top: $margin-top-play-pause-podcast-teaser;
        }
    }

    .su-podcast.su-card {
        padding: $padding-podcast-card;
        background: $background-after-podcast-card;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-image: var(--background-podcast);
            background-size: cover;
            background-position: 50%;
            filter: $filter-after-podcast-card;
            z-index: -1;
        }
        .su-media-container {
            margin: $margin-media-container-podcast-card;
        }
        &.su-negative {
            .su-metadata,
            .su-source,
            .su-date {
                color: $color-metadata-negative-podcast-card;
            }
        }
    }

    .su-podcast-channel.su-teaser {
        gap: $gap-podcast-channel;

        $font-size-standfirst-articles-list: 1rem;

        .su-standfirst.su-truncate-1 {
            max-height: $max-height-standfirst-1line-podcast-channel;
        }
        .su-standfirst.su-truncate-2 {
            max-height: $max-height-standfirst-2lines-podcast-channel;
        }
        .su-standfirst.su-truncate-3 {
            max-height: $max-height-standfirst-3lines-podcast-channel;
        }
        .su-standfirst.su-truncate-4 {
            max-height: $max-height-standfirst-4lines-podcast-channel;
        }
    }
}
