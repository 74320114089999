/*
    Styles du composant [teaser] de SipaUI
*/
@import "variables";

// TEASERS
.sipaui {
    .su-teaser,
    .su-teaser-big,
    .su-teaser-text {
        display: grid;
        gap: $gap-teaser;

        .su-media-container {
            width: $width-figure-teaser;
        }
        .su-standfirst {
            display: none;
        }
    }
}


// TEASER STANDARD
.sipaui {
    .su-teaser,
    .su-teaser-big {
        grid-template-columns: $grid-template-columns-teaser;
        grid-template-rows: $grid-template-rows-teaser;

        .su-title {
            grid-area: $grid-area-titre-teaser-sans-image;
        }
        .su-metadata {
            grid-area: $grid-area-metadata-teaser-sans-image;
            align-items: flex-start;
        }
        .su-media-container {
            grid-area: $grid-area-figure-teaser;
        }
        .su-media-container ~ .su-title {
            grid-area: $grid-area-titre-teaser;
        }
        .su-media-container ~ .su-metadata {
            grid-area: $grid-area-metadata-teaser;
        }
        .su-media-container:has(.su-visually-hidden) {
            display: none;

            ~ .su-title {
                grid-area: $grid-area-titre-teaser-sans-image;
            }
            ~ .su-metadata {
                grid-area: $grid-area-metadata-teaser-sans-image;
            }
        }
    }
}

// GRAND TEASER
@media screen and (min-width: $min-breakpoint-lg) {
    .sipaui {
        .su-teaser-big {
            grid-template-columns: $grid-template-columns-grand-teaser;
            grid-template-rows: $grid-template-rows-grand-teaser;
            column-gap: $column-gap-grand-teaser;

            .su-title {
                font-size: $font-size-titre-grand-teaser;
                line-height: $line-height-titre-grand-teaser;
                grid-area: $grid-area-titre-grand-teaser-sans-image;
            }
            .su-standfirst {
                display: block;
                overflow: hidden;
                margin: $margin-chapeau-grand-teaser;
                grid-area: $grid-area-chapeau-grand-teaser-sans-image;
                @supports (display: -webkit-box) {
                    display: -webkit-box;
                    -webkit-line-clamp: $line-clamp-chapeau-grand-teaser;
                    -webkit-box-orient: vertical;
                }
            }
            .su-metadata {
                grid-area: $grid-area-metadata-grand-teaser-sans-image;
            }
            .su-media-container {
                grid-area: $grid-area-figure-grand-teaser;
                width: $width-figure-grand-teaser;
            }
            .su-media-container ~ .su-title {
                grid-area: $grid-area-titre-grand-teaser;
            }
            .su-media-container ~ .su-standfirst {
                grid-area: $grid-area-chapeau-grand-teaser;
            }
            .su-media-container ~ .su-metadata {
                grid-area: $grid-area-metadata-grand-teaser;
            }
            .su-media-container:has(.su-visually-hidden) {
                ~ .su-standfirst {
                    grid-area: $grid-area-chapeau-grand-teaser-sans-image;
                }
                ~ .su-metadata {
                    grid-area: $grid-area-metadata-grand-teaser-sans-image;
                }
            }
        }
    }
}

// TEASER SPONSORISÉ
.sipaui {
    .su-articles-area:not(.su-sponsored-articles) {
        .su-sponsored:not([aria-description]) { // :not([aria-description]) pour rétrocompatibilité à supprimer dans SipaUI 8
            &[class*="su-teaser"] {
                padding: $pading-teaser-sponsorised;
            }
            &.su-teaser,
            &.su-teaser-big {
                .su-media-container {
                    margin: $margin-media-container-teaser-sponsorised;
                }
                .su-main-picture {
                    border-radius: 0;
                }
                .su-media-container + .su-badge {
                    grid-area: $grid-area-badge-teaser-sponsored;
                }
                .su-badge + .su-title {
                    grid-area: $grid-area-titre-teaser-sponsored;
                }
                &:has(.su-badge) {
                    grid-template-rows: $grid-template-rows-teaser-sponsored;
                    .su-media-container {
                        grid-area: $grid-area-figure-teaser-sponsored;
                    }
                    .su-title ~ .su-metadata {
                        grid-area: $grid-area-metadata-teaser-sponsored;
                    }
                }
            }
            &.su-teaser-text {
                &:has(.su-badge) {
                    grid-template-rows: $grid-template-rows-teaser-sponsored;
                }
            }
        }
        .su-teaser.su-sponsored[aria-description] { // CSS pour rétrocompatibilité à supprimer dans SipaUI 8
            padding-top: 36px !important;

            &::before {
                position: absolute;
                top: 10px;
                left: 10px;
            }
        }
    }
}

@media screen and (min-width: $min-breakpoint-lg) {
    .sipaui {
        .su-articles-area:not(.su-sponsored-articles) {
            .su-sponsored:not([aria-description]) { // :not([aria-description]) pour rétrocompatibilité à supprimer dans SipaUI 8
                &.su-teaser-big {
                    &:has(.su-badge) {
                        grid-template-rows: $grid-template-rows-grand-teaser-sponsored;

                        .su-media-container {
                            grid-area: $grid-area-figure-grand-teaser-sponsored;
                        }
                        .su-title + .su-standfirst {
                            grid-area: $grid-area-standfirst-grand-teaser-sponsored;
                        }
                        .su-title ~ .su-metadata {
                            grid-area: $grid-area-metadata-grand-teaser-sponsored;
                        }
                    }
                }
            }
        }
    }
}


// GRAND POSTER
.sipaui {
    .su-teaser-poster {
        .su-title {
            margin-top: $margin-top-teaser-poster-title;
            line-height: $line-height-teaser-poster-title;
        }
        .su-description {
            margin-top: $margin-top-teaser-poster-description;
        }
        .su-title,
        .su-description {
            text-align: center;
        }
        &.su-teaser-poster-small {
            .su-title {
                font-size: $font-size-teaser-poster-small-title;
            }
        }
        &.su-teaser-poster-big {
            .su-title {
                font-size: $font-size-teaser-poster-big-title;
            }
        }
    }
}
