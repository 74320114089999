/*
    Typographie
*/

$margin-paragraphe: var(--marge-m);


// TEXTE EDITORIAL STANDARD--------------------------------------------------------------------------------------------------------------------------------------------------------------------------/
.sipaui {
    font-family: $font-family-base;
    font-size: $font-size-standard;
    line-height: $line-height-standard;
    color: $font-color-standard;

    :is(.su-article,
    .su-articles-area,
    .su-articles-list) {
        font-family: $font-family-base;
        font-size: $font-size-standard;
        line-height: $line-height-edito;
        color: $font-color-standard;
    }
    .su-article {
        font-size: $font-size-standard-edito;

        :where(p + p,
        ul + p,
        ol + p) {
            margin-top: $margin-paragraphe;
        }
    }
    .su-text-standard {
        font-family: $font-family-base !important;
        font-size: $font-size-standard !important;
        line-height: $line-height-standard !important;
        color: $font-color-standard !important;
    }
    .su-text-standard + .su-text-standard {
        margin-top: $margin-paragraphe;
    }
    .su-text-standard-editorial {
        font-family: $font-family-base !important;
        font-size: $font-size-standard-edito !important;
        line-height: $line-height-edito !important;
        color: $font-color-standard !important;
    }
    .su-text-standard-editorial + .su-text-standard-editorial {
        margin-top: $margin-paragraphe;
    }

    .su-text-xsmall {
        font-size: var(--font-size-xs) !important;
    }
    .su-text-small {
        font-size: var(--font-size-s) !important;
    }
    .su-text-medium {
        font-size: var(--font-size-ms) !important;
    }
    .su-text-big {
        font-size: var(--font-size-m) !important;
    }

    .su-text-light {
        color: $font-color-light !important;
    }
    .su-text-xlight {
        color: $font-color-xlight !important;
    }

    :where(.su-metadata),
    .su-article.su-metadata {
        color: $color-metadata;
        font-size: $font-size-metadata;
        line-height: $line-height-standard;
    }
    .su-article .su-metadata,
    .su-article.su-metadata {
        font-size: $font-size-metadata-edito;
    }

    // COULEUR NÉGATIVE -------------------------------------/
    &.su-negative,
    .su-negative {
        color: $font-color-negative;

        &,
        &:is(.su-article,
            .su-articles-area,
            .su-articles-list),
        :is(.su-article,
        .su-articles-area,
        .su-articles-list) {
            color: $font-color-negative;
        }
        :is(.su-text-standard,
            .su-text-standard-editorial),
        &:is(.su-text-standard,
            .su-text-standard-editorial) {
            color: $font-color-negative !important;
        }
        :where(.su-metadata),
        .su-article.su-metadata {
            color: $color-metadata-negative;
        }
    }
    &.su-negative .su-text-light,
    .su-negative .su-text-light,
    .su-text-light.su-negative {
        color: $font-color-light-negative !important;
    }
    &.su-negative .su-text-xlight,
    .su-negative .su-text-xlight,
    .su-text-xlight.su-negative {
        color: $font-color-xlight-negative !important;
    }
}

// LIEN STANDARD ------------------------------------------------------------------------------------------/
.sipaui {
    a:where(:link,
    :visited) {
        color: $color-link-standard;
        text-decoration: none;
    }
    a:where(:hover,
    :focus,
    :active) {
        color: $color-link-standard-hover;
        opacity: $opacity-link-standard-hover;
    }

    // COULEUR NÉGATIVE -------------------------------------/
    .su-negative,
    .su-negative .su-text-standard,
    .su-negative:is(.su-text-standard,
    .su-articles-area,
    .su-articles-list) {
        a:link,
        a:visited {
            color: $color-link-negative;
        }
        // a:is(:hover,
        // :focus,
        // :active) {
        //     color: $color-link-negative-hover;
        // }
    }
}

// CODE ------------------------------------------------------------------------------------------/
.sipaui {
    .su-code-box {
        background: rgba($gris, .4);
        border-radius: 5px;
        border: 1px solid $gris;
        padding: var(--marge-xs);
        margin: var(--marge-s) 0;
        position: relative;

        pre {
            margin: 0;
            padding: 0;
        }
    }
}
