/*
    Styles du composant [Onglets] de SipaUI
*/
@import "variables";



.sipaui {
    .su-tabs {
        display: inline-flex;
        position: relative;
        padding: 0;
        margin: 0;
        overflow-x: auto;
        scroll-padding: 1rem;
        scrollbar-width: none;
        background: $background-tabs-area;
        border-top: $border-top-tabs-area;
        border-right: $border-right-tabs-area;
        border-bottom: $border-bottom-tabs-area;
        border-left: $border-left-tabs-area;

        a {
            display: block;
            height: $height-tabs;
            line-height: $height-tabs;
            font-size: $font-size-tabs;
            color: $color-tabs;
            font-weight: $font-weight-tabs;
            font-style: $font-style-tabs;
            font-variant: $font-variant-tabs;
            text-transform: $text-transform-tabs;
            padding: $padding-tabs;
            border-top: $border-top-tabs;
            border-right: $border-right-tabs;
            border-bottom: $border-bottom-tabs;
            border-left: $border-left-tabs;
            white-space: nowrap;
            
            &:hover,
            &:focus,
            &:active,
            &:visited {
                color: $color-tabs-hover;
            }
        }
        a.su-tab-on,
        a.su-tab-on:hover,
        a.su-tab-on:focus,
        a.su-tab-on:active,
        a.su-tab-on:visited {
            color: $font-color-tab-on;
            font-weight: $font-weight-tab-on;
            border-top: $border-top-tab-on;
            border-right: $border-right-tab-on;
            border-bottom: $border-bottom-tab-on;
            border-left: $border-left-tab-on;
        }
        &.su-fullwidth {
            display: flex;
            
            &::after {
                content: '';
                height: $height-tabs;
                width: 100%;
                border-top: $border-top-tabs;
                border-right: $border-right-tabs;
                border-bottom: $border-bottom-tabs;
                border-left: $border-left-tabs;
            }
        }
        &.su-carrousel-container {
            gap: 0;
        }
    }
    [class*="su-tab-content"] {
        display: none;
    }
    [class*="su-tab-content"].su-tab-on {
        display: block;
    }
}

// CARROUSEL
@media (hover: none) {
    .su-carrousel:has(.su-navigation-button + .su-navigation-button:not(.su-inactive)) .su-tabs {
        mask-image: $mask-image-carrousel-tabs;
    }
}

// ONGLETS DISTRIBUÉS
.sipaui {
    .su-tabs.su-distributed {
        display: flex;
        
        li {
            flex: 1;
            text-align: center;
        }
        .su-truncate-1 {
            white-space: normal;
        }
    }
}

// FOND SOMBRE
.sipaui {
    &.su-negative .su-tabs,
    .su-negative .su-tabs {
        a {
            color: $color-tabs-negative;
            border-top: $border-top-tabs-negative;
            border-right: $border-right-tabs-negative;
            border-bottom: $border-bottom-tabs-negative;
            border-left: $border-left-tabs-negative;
            
            &:hover,
            &:active,
            &:visited {
                color: $color-tabs-negative-hover;
            }                                                                        
        }
        a.su-tab-on,
        a.su-tab-on:hover,
        a.su-tab-on:focus,
        a.su-tab-on:active,
        a.su-tab-on:visited {
            color: $font-color-tab-on-negative;
            border-bottom: 2px solid $border-bottom-tab-on-negative;
        }
    }
}
