/*
    Styles du composant [Search-fields] de SipaUI
*/
@import "variables";

.sipaui {
    .su-search {
        position: relative;        
        
        input[type="search"]::-webkit-search-decoration,
        input[type="search"]::-webkit-search-cancel-button,
        input[type="search"]::-webkit-search-results-button,
        input[type="search"]::-webkit-search-results-decoration {
            display: none;
        }

        label {
            .su-icon {
                font-size: 36px;
            }
        }
        &:not(.su-input-group) button[type="submit"] {
            display: none;
        }
        &.su-submit {
            label {
                display: none;
            }
            button[type="submit"] {
                display: block;
            }
        }
        
        
        //Auto-complétion
        &.su-autocomplete {
            &.su-open input[type="search"] {
                border-color: $border-color-input-search-open;
                background: $background-input-search-open;
                border-radius: $border-radius-input-search-open;
            }
            .su-suggestions {
                position: absolute;
                top: calc(var(--taille-tactile) - 1px);
                left: 0;
                right: 0;
                visibility: hidden;
                opacity: 0;
                border: $border-suggestions-search;
                border-radius: $border-radius-suggestions-search;
                background: $background-suggestions-search;
                border-top: 0 !important;
                margin: 0;
                padding: $padding-suggestions-search;
                z-index: 1;
                transition: opacity .2s ease-out, border .3s ease-out;
                
                ul {
                    margin: 0;
                    padding: 0;
                }
                &::before {
                    content: "";
                    display: block;
                    height: 1px;
                    position: absolute;
                    top: 0;
                    left: var(--marge-s);
                    right: var(--marge-s);
                    background: $background-suggestions-search-top-border;
                }
            }
            &.su-open .su-suggestions {
                visibility: visible;
                opacity: 1;
                border: $border-suggestions-search-open;
            }
        }
        .su-suggestion {
            height: var(--taille-tactile-mini);
            line-height: var(--taille-tactile-mini);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: $font-weight-suggestion-search;
            color: $color-suggestion-search;
            a {
                display: block;
            }
            span,
            strong,
            em {
                font-weight: $font-weight-suggestion-match-search;
                font-style: $font-style-suggestion-match-search;
                color: $color-suggestion-match-search;
            }
            &:hover,
            &:active,
            &:focus {
                cursor: pointer;
                    color: $color-suggestion-search-hover;
                span,
                strong,
                em {
                    color: $color-suggestion-match-search-hover;
                }
            }
        }
    }
}

