/*
    Styles du composant [close-button] de SipaUI
*/
@import "variables";

.sipaui {
    .su-close {
        width: $width-close;
        height: $height-close;
        padding: 0;
        background: none;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        
        @include hover-active-focus {
            opacity: $opacity-hover;
        }
    }
}
