/*
    Styles du composant [card] de SipaUI
*/
@import "variables";
@import "../../Text/Titles/variables";
@import "../../Text/Sources/variables";
@import "../../Text/Date/variables";


// FOCUS STANDARD
.sipaui {
    ol.su-ranked-list {
        counter-reset: rankedArticles;

        li {
            counter-increment: rankedArticles;
            display: flex;
            gap: $gap-ranked-articles-number;

            &::before {
                content: counter(rankedArticles);
                line-height: $line-height-ranked-articles-number;
                color: $color-ranked-articles-number;
                font-size: $font-size-ranked-articles-number;
            }
            .su-badge {
                display: block;
                                                            margin-top: var(--marge-s);

                &::before {
                    display: inline-flex; // Pour supprimer l’espace en-dessous provoqué par l’inline-block
                }
            }
        }
        &.su-columns-no-cut {
            li {
                display: inline-flex;
            }
        }
    }
}

// FOCUS SPONSORISÉ
.sipaui {
    ol.su-ranked-list {

        li:has(.su-sponsored) {
            background: $background-ranked-article-sponsored;
            padding: $padding-ranked-article-sponsored;
            border-radius: $border-radius-ranked-article-sponsored;
        }
    }
}

@media screen and (min-width: 61.25em) {
    .sipaui {
        ol.su-ranked-list {
            &[class*="su-columns-desktop-"] {
                .su-title {
                    min-height: $min-height-title-ranked-articles-in-columns;
                }
            }
        }
    }
}
