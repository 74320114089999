/*
    Styles du composant [accordions] de SipaUI
*/
@import "variables";

// ACCORDÉON GÉNÉRIQUE --------------------------------------------------------------------------------------/
.sipaui {
    .su-accordion-chevron,
    .su-accordion-radio {
        position: relative;
        min-width: 140px;
        margin: 0;
    }
    [class*="su-accordion"] {
        dd {
            margin: 0;
        }

        .su-accordion-item {
            margin-bottom: $margin-bottom-accordion-item;
            position: relative;

            > label,
            > dt > label {
                height: auto; // pour contrer le height défini en standard sur les label SUI
                cursor: pointer;
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin: 0;
                border-top: $border-top-accordion-label;
                border-right: $border-side-accordion-label;
                border-bottom: $border-bottom-accordion-label;
                border-left: $border-side-accordion-label;
                border-radius: $border-radius-accordion $border-radius-accordion 0 0;
                background: $background-accordion-label;
                font-size: $font-size-accordion-label;
                font-weight: $font-weight-accordion-label;
                font-style: normal;
                color: $color-accordion-label;

                @include hover-active-focus {
                    border-top: $border-top-accordion-label-hover;
                    border-right: $border-side-accordion-label-hover;
                    border-bottom: $border-bottom-accordion-label-hover;
                    border-left: $border-side-accordion-label-hover;
                    background: $background-accordion-label-hover;
                    z-index: 1; // permet de passer au-dessus des autres qd les items se touchent avec une marge de -1px;
                }
            }
            > label,
            > dt {
                @include hover-active-focus {
                    ~ .su-accordion-content {
                        border-top: $border-top-accordion-content-hover;
                        border-right: $border-side-accordion-content-hover;
                        border-bottom: $border-bottom-accordion-content-hover;
                        border-left: $border-side-accordion-content-hover;
                        background: $background-accordion-content-hover;
                        z-index: 1; // permet de passer au-dessus des autres qd les items se touchent avec une marge de -1px;
                    }
                }
            }

            > input:checked {
                + label,
                + dt > label {
                    border-top: $border-top-accordion-label-checked;
                    border-right: $border-side-accordion-label-checked;
                    border-bottom: $border-bottom-accordion-label-checked;
                    border-left: $border-side-accordion-label-checked;
                    background: $background-accordion-radio-label-checked;
                    z-index: 1; // permet de passer au-dessus des autres qd les items se touchent avec une marge de -1px;
                }
                ~ .su-accordion-content {
                    border-top: $border-top-accordion-content-checked;
                    border-right: $border-side-accordion-content-checked;
                    border-bottom: $border-bottom-accordion-content-checked;
                    border-left: $border-side-accordion-content-checked;
                    background: $background-accordion-radio-content-checked;
                    padding: $padding-accordion-content-checked;
                    z-index: 1; // permet de passer au-dessus des autres qd les items se touchent avec une marge de -1px;
                    max-height: none;
                }
            }
            > input[type=radio]:checked {
                + label,
                + dt > label {
                    cursor: default;
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        //Inactif
        &.su-disabled .su-accordion-item,
        .su-accordion-item.su-disabled {
            > label,
            > dt > label {
                cursor: default;

                @include hover-active-focus {
                    border-top: $border-top-accordion-label;
                    border-right: $border-side-accordion-label;
                    border-bottom: $border-bottom-accordion-label;
                    border-left: $border-side-accordion-label;
                    background: $background-accordion-label;
                }
            }
            > label,
            > dt {
                @include hover-active-focus {
                    ~ .su-accordion-content {
                        border-top: $border-top-accordion-content;
                        border-right: $border-side-accordion-content;
                        border-bottom: $border-bottom-accordion-content;
                        border-left: $border-side-accordion-content;
                        background: $background-accordion-content;
                    }
                }
            }
        }
    }
    .su-accordion-content {
        padding: $padding-accordion-content;
        overflow: hidden;
        background: $background-accordion-content;
        border-top: $border-top-accordion-content;
        border-right: $border-side-accordion-content;
        border-bottom: $border-bottom-accordion-content;
        border-radius: 0 0 $border-radius-accordion $border-radius-accordion;
        border-left: $border-side-accordion-content;
        max-height: $border-radius-accordion;

        *:last-child {
            margin-bottom: 0;
        }
    }
    // Transitions
    // standard
    .su-accordion-chevron,
    .su-accordion-radio {
        &:not(.su-no-transition) .su-accordion-item input[type="checkbox"] ~ .su-accordion-content {
            transition: max-height .2s, padding .2s;
        }
        &:not(.su-no-transition) .su-accordion-item input[type="checkbox"]:checked ~ .su-accordion-content {
            max-height: 1000px;
            transition: max-height 2s ease-out, padding .3s;
        }
        // autofermante
        &:not(.su-no-transition) .su-accordion-item input[type="radio"] ~ .su-accordion-content {
            transition: max-height 0s;
        }
        &:not(.su-no-transition) .su-accordion-item input[type="radio"]:checked ~ .su-accordion-content {
            max-height: 1000px;
            transition: max-height 2s ease-out;
        }
    }
}

// ACCORDÉON CHEVRON ------------------------------------------------------------------------------------------/
.sipaui {
    .su-accordion-chevron {
        .su-accordion-item {
            > input {
                display: none;
            }
            > label,
            > dt > label {
                min-height: $min-height-accordion-chevron-label;
                padding: $padding-accordion-chevron-label;
                position: relative;

                &::after {
                    content: "\e901";
                    display: block;
                    position: absolute;
                    top: calc(50% + #{$border-radius-accordion}/2);
                    transform: translateY(-50%);
                    right: 7px;
                    font-family: su-icons;
                    font-size: 3.2rem;
                    font-weight: var(--font-weight-normal);
                    color: $color-chevron-accordion;
                    font-style: normal;
                    font-variant: normal;
                    line-height: var(--line-height-xs);
                    -webkit-font-feature-settings: "liga";
                      -moz-font-feature-settings: "liga=1";
                      -moz-font-feature-settings: "liga";
                      -ms-font-feature-settings: "liga" 1;
                      font-feature-settings: "liga";
                      -webkit-font-variant-ligatures: discretionary-ligatures;
                      font-variant-ligatures: discretionary-ligatures;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing:grayscale;
                    transition: transform .3s;
                }
            }
            > input:checked {
                + label,
                + dt > label {
                    &::after {
                        color: $color-chevron-accordion-checked;
                        transform: translateY(-50%) rotate(-180deg);
                        transition: transform .3s ease-in-out;
                    }
                }
            }
        }
    }
}

// ACCORDÉON-RADIO ------------------------------------------------------------------------------------------/
.sipaui {
    .su-accordion-radio {
        .su-accordion-item {
            > input[type="radio"] {
                top: $top-accordion-radio-radio-button;
                left: $left-accordion-radio-radio-button;
            }
            > label {
                min-height: $min-height-accordion-radio-label;
                padding: $padding-accordion-radio-label;
            }
        }
    }
}

// NÉGATIF ---------------------------------------------------------------------------------------/

.sipaui {
    &.su-negative [class*="su-accordion"],
    .su-negative [class*="su-accordion"],
    [class*="su-accordion"].su-negative {
        .su-accordion-item {
            > label,
            > dt > label {
                background: none;
                border-color: $border-color-accordion-negative;

                @include hover-active-focus {
                    background: none;
                    border-color: $border-color-accordion-hover-negative;
                }
            }
            > label,
            > dt {
                @include hover-active-focus {
                    ~ .su-accordion-content {
                        background: none;
                        border-color: $border-color-accordion-hover-negative;
                    }
                }
            }
            > input:checked {
                + label,
                + dt > label {
                    background: none;
                    border-color: $border-color-accordion-checked-negative;
                }
                ~ .su-accordion-content {
                    background: none;
                    border-color: $border-color-accordion-checked-negative;
                }
            }
        }
        .su-accordion-content {
            background: none;
            border-color: $border-color-accordion-negative;
        }
        .su-accordion-item {
            > label,
            > dt > label {
                &::after {
                    color: $color-chevron-accordion-negative;
                }
            }
            > input:checked {
                + label,
                + dt > label {
                    &::after {
                        color: $color-chevron-accordion-checked-negative;
                    }
                }
            }
        }

        //Inactif
        &.su-disabled .su-accordion-item,
        .su-accordion-item.su-disabled {
            > label,
            > dt > label {
                cursor: default;

                @include hover-active-focus {
                    background: none;
                    border-color: $border-color-accordion-negative;
                }
            }
            > label,
            > dt {
                @include hover-active-focus {
                    ~ .su-accordion-content {
                        background: none;
                    border-color: $border-color-accordion-negative;
                    }
                }
            }
        }
    }
}











