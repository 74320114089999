/*
    Styles du composant [premium-title] de SipaUI
*/
@import "variables";

// GÉNÉRIQUE
.sipaui {
    // [class*="su-live-"] {
    // }
    .su-live-open {
        .su-title::before,
        h1::before {
            content: $content-icone-direct;
            position: relative;
            top: $top-icone-direct;
            margin-right: $margin-right-icone-direct;
            left: $left-icone-direct;
            font-family: 'su-icons';
            color: $color-icone-direct;
            font-size: $font-size-icone-diretc;
            font-weight: $font-weight-icone-direct;
            line-height: .1;
        }
    }
}

// DÉTAIL ARTICLE
.sipaui {
    .su-article.su-live-open {
        h1::before {
            top: auto;
            bottom: $bottom-icone-direct-DA;
            font-size: $font-size-icone-diretc-DA;
        }
    }
}

// ANIMATION
@keyframes clignotant {
    from {
        opacity: .2;
    }
    to {
        opacity: 1;
    }
}
.sipaui {
    .su-live-open.su-animated-icon .su-title::before,
    .su-article.su-live-open.su-animated-icon h1::before {
        animation: 1.5s infinite alternate clignotant;
    }
}
