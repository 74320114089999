/*
    Styles du composant [password-fields] de SipaUI
*/
@import "variables";

.sipaui {
    // .su-toggle-text {                                                                obsolète ?
    //     .su-input-action {
    //         color: $color-main-7;
    //     }
    //     .su-input-action:hover {
    //         color: $color-main-5;
    //     }
    // }

    .su-password {
        position: relative;

        .su-conditions {
            display: none;
            margin: $margin-password-conditions;
            span {
                display: block;
                font-size: var(--font-size-s);
                color: $color-main-6;

                &.su-conditions-success {
                    color: $color-success;
                }

                .su-icon {
                    margin-right: var(--marge-xs);
                    font-size: var(--font-size-s);
                    position: relative;
                    top: 1px;
                    left: -2px;
                }
            }
        }

        .su-input-actions-area {
            display: none;
            opacity: 1;
            .su-input-action {
                cursor: pointer;
            }
        }

        .su-password-toggle {
            .su-icon {
                font-size: 30px;
            }
        }

        &.su-not-empty {
            .su-input-actions-area {
                display: flex;
            }
        }

        input:focus {
            & ~ .su-conditions {
                display: block;
            }
        }

        &.su-error, &.su-password-focus {
            .su-conditions {
                display: block;
            }
        }

        &.su-error {
            .su-conditions {
                span {
                    &.su-conditions-error {
                        color: $color-error;
                    }
                }
            }
        }
    }
}















