/*
    Styles du composant [Audio-player] de SipaUI
*/
@import "variables";

// LIGHT --------------------------------------------------------------------------------------/
.sipaui {
    .su-audio-player {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background: $background-audio-player;
        height: auto; // utile pour popover
        padding: 0; // utile pour popover
        border: 0; // utile pour popover
        width: 100%; // utile pour popover
        top: auto; // utile pour popover
        overflow: visible; // utile pour popover
        
        .su-container {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: $gap-audio-player-container;
            height: $height-audio-player-container;
        }
        .su-identity-container,
        .su-controls-container,
        .su-time-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: $gap-audio-player-subcontainers;
        }
        .su-identity-container,
        .su-time-container {
            flex: $flex-audio-player-subcontainers;
        }
        .su-identity-container figure {
            width: $width-audio-player-figure;
            height: $height-audio-player-figure;
        }
        .su-main-picture {
            border-radius: $border-radius-audio-player-main-picture;
        }
        .su-identity {
            flex: 1;
        }
        .su-channel {
            margin-bottom: $margin-bottom-audio-player-channel;
        }
        .su-title {
            font-size: $font-size-audio-player-title;
            font-weight: $font-weight-audio-player-title;
        }
        .su-controls-container {
            .su-backward,
            .su-forward {
                width: $width-audio-player-ward-buttons;
                height: $height-audio-player-ward-buttons;
                
                &:hover:not(.su-disabled):not([disabled]) {
                    opacity: $opacity-hover;
                }
            }
        }
        .su-button.su-primary,
        .su-button.su-primary:hover:not(.su-disabled):not([disabled]) {
            background: $background-audio-player-play-pause-button;
            color: $color-audio-player-play-pause-button;
        }
        .su-time-container {
            padding-right: $padding-right-time-container;
        }
        .su-time {
            font-size: $font-size-audio-player-time;
        }
        .su-close {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            width: $width-audio-player-close;
            height: $height-audio-player-close;
            margin-right: $marge-container-xs;
        }
    }
}


// Bouton fermeture ------------------------------------------------------------------------------------------/
@media screen and (min-width: $min-breakpoint-sm) {
    .sipaui .su-audio-player {
        .su-close {
            margin-right: $marge-container-sm;
        }
    }
}

@media screen and (min-width: $min-breakpoint-md) {
    .sipaui .su-audio-player {
        .su-close {
            margin-right: $marge-container-md;
        }
    }
}

@media screen and (min-width: $min-breakpoint-lg) {
    .sipaui .su-audio-player {
        .su-close {
            margin-right: $marge-container-lg;
        }
    }
}

// Mobile ------------------------------------------------------------------------------------------/
@media screen and (max-width: $max-breakpoint-sm) {
    .sipaui .su-audio-player {
        .su-container {
            gap: $gap-audio-player-container-mobile;
        }
        .su-identity-container {
            order: 2;
            padding-right: calc(var(--taille-tactile-mini) + var(--marge-s));
        }
        .su-controls-container {
            order: 1;
        }
        figure,
        .su-backward,
        .su-forward,
        .su-time {
            display: none;
        }
        .su-time-container {
            position: absolute;
            top: calc(($height-progress-bar + (($height-progress-bar-cursor - $height-progress-bar) / 2)) * -1);
            left: 0;
            right: 0;
            padding: 0;
        }
    }
}
