/*
    Styles du composant [accordions] de SipaUI
*/
@import "variables";

// ACCORDÉON GÉNÉRIQUE --------------------------------------------------------------------------------------/
.sipaui {
    [class*="su-collapsed-text"] {
        position: relative;
        padding-right: var(--taille-tactile);

        .su-toggle-text  {
            position: absolute;
            color: $color-chevron-collapsed-text;
            transition: transform .3s ease-in-out;
            top: $top-chevron-collapsed-text;
            right: $right-chevron-collapsed-text;
            width: $width-chevron-collapsed-text;
            height: $height-chevron-collapsed-text;

            &:hover {
                opacity: var(--opacite-survol);
            }
        }
        &:not(.su-truncate-2) {
            .su-toggle-text  {
                transform: rotate(-180deg);
            }
        }
    }
    .su-negative {
        [class*="su-collapsed-text"] {
            .su-toggle-text  {
                color: $color-chevron-collapsed-text-negative;
            }
        }
    }
}

@media screen and (min-width: $min-breakpoint-md) {
    .sipaui {
        .su-collapsed-text-sm {
            padding-right: 0;
            -webkit-line-clamp: none;

            .su-toggle-text {
                display: none;
            }
        }
    }
}
