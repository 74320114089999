/*
    Styles du composant [location] de SipaUI
*/
@import "variables";

$mask-icon-location: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMzMzIj48cGF0aCBkPSJtMTIgMTJjLTEuNjUgMC0zLTEuMzUtMy0zczEuMzUtMyAzLTMgMyAxLjM1IDMgMy0xLjM1IDMtMyAzem0wLTVjLTEuMSAwLTIgLjktMiAycy45IDIgMiAyIDItLjkgMi0yLS45LTItMi0yeiIvPjxwYXRoIGQ9Im0xMiAyMWMtLjEzIDAtLjI1LS4wNS0uMzUtLjE0LS4zMS0uMzEtNy42NS03LjU1LTcuNjUtMTEuODZzMy41OS04IDgtOCA4IDMuNTkgOCA4LTcuMzQgMTEuNTUtNy42NSAxMS44NmMtLjEuMS0uMjIuMTQtLjM1LjE0em0wLTE5Yy0zLjg2IDAtNyAzLjE0LTcgNyAwIDMuMzkgNS41NCA5LjI5IDcgMTAuNzkgMS40Ni0xLjUgNy03LjQgNy0xMC43OSAwLTMuODYtMy4xNC03LTctN3oiLz48cGF0aCBkPSJtMTUuNSAyM2gtN2MtLjI4IDAtLjUtLjIyLS41LS41cy4yMi0uNS41LS41aDdjLjI4IDAgLjUuMjIuNS41cy0uMjIuNS0uNS41eiIvPjwvZz48L3N2Zz4=);


// AUTEUR GÉNÉRIQUE-----------------------------------------------------------------------------------------/
.sipaui {
    .su-location {
        font-family: $font-family-location;
        font-size: $font-size-location;
        line-height: $line-height-location;
        color: $color-location;
        font-weight: $font-weight-location;
        position: relative;
        padding: $padding-location;

        &::before {
            content: '';
            background: $color-icon-location;
            display: inline-block;
            position: absolute;
            left: $left-icon-location;
            top: $top-icon-location;
            width: $width-icon-location;
            height: $height-icon-location;
            -webkit-mask: $mask-icon-location;
            mask: $mask-icon-location;
            -webkit-mask-size: cover;
            mask-size: cover;
        }
    }
    .su-negative .su-location,
    .su-negative.su-location {
        color: $color-location-negative;

        &::before {
            background: $color-icon-location-negative;
        }
    }
}
