/*
    Styles du composant [Tags] de SipaUI
*/
@import "variables";

@mixin su-tag-contrast {
    background: $background-tag-contrast;
}
@mixin su-tag-wide {
    height: $height-tag-wide;
    line-height: $line-height-tag-wide;
    padding: $padding-tag-wide;
    font-size: $font-size-tag-wide;
}

.sipaui {
    .su-tag {
        display: inline-flex;
        align-items: center;
        height: $height-tag;
        line-height: $height-tag;
        padding: $padding-tag;
        border-radius: $border-radius-tag;
        border: $border-tag;
        background: $background-tag;
        font-size: $font-size-tag;
        font-style: $font-style-tag;
        font-weight: $font-weight-tag;
        color: $color-tag;
        opacity: $opacity-tag;
        cursor: pointer;
        //CSS pour bloquer le contenu trop long dans un tag
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100%;
        vertical-align: top; //empêche une "fausse marge" basse provoquée par l’overflow


        &:hover {
            background: $background-tag-hover;
            color: $color-tag-hover;
            opacity: $opacity-tag-hover;
        }
        &:active,
        &:focus {
            background: $background-tag;
            color: $color-tag;
            opacity: $opacity-tag;
        }

        .su-icon,
        svg,
        img {
            margin-right: var(--marge-xs);
            max-height: $height-icon;
        }
        &.su-stroke {
            svg,
            svg path {
                // fill: $color-button-primary !important;
                fill: none !important;
                stroke: currentColor !important;
            }
        }
    }
    .su-tag.su-contrast {
        @include su-tag-contrast;
    }
    .su-tag.su-wide {
        @include su-tag-wide;
    }

    .su-tags {
        display: flex;
        flex-flow: row wrap;
        gap: $gap-tag;
        padding: 0;
        margin: 0;

        li {
            white-space: nowrap;

            a:link,
            a:visited {
                @extend .su-tag
            }
        }
        &.su-contrast {
            li a:link,
            li a:visited {
                @include su-tag-contrast;
            }
        }
        &.su-wide {
            li a:link {
                @include su-tag-wide;
            }
        }
    }
    .su-carrousel .su-tags {   //Surcharge du Carrousel
        gap: $gap-tag;
    }
    .su-contrast {
        .su-tags {
            li a:link,
            li a:visited {
                @include su-tag-contrast;
            }
        }
    }
    .su-wide {
        .su-tags {
            li a:link {
                @include su-tag-wide;
            }
        }
    }
}



// CARROUSEL
@media (hover: none) or (pointer: coarse) {
    .su-carrousel:has(.su-navigation-button + .su-navigation-button:not(.su-inactive)) .su-tags {
        mask-image: $mask-image-carrousel-tags;
    }
}
