/*
    SipaUI
    fonctions et mixin SASS
*/

@mixin hover-active {
    &:not(:disabled) {
        &:hover,
        &:active {
            @content
        }
    }
}

@mixin hover-active-focus {
    &:not(:disabled) {
        &:hover,
        &:active,
        &:focus {
            @content
        }
    }
}

@mixin clearfix() {
    &::after {
        content: '';
        display: table;
        clear: both;
    }
}

// appearence
@mixin appearance ($appearance) {
    -webkit-appearance: $appearance;
    appearance: $appearance;
}

// pour les hide et show selon les breakpoints
@mixin responsive-visibility($parent, $type:null) {
    @if ($type) {
        #{$parent} {
            display: $type !important;
        }
    }
    @else {
        #{$parent} {
            display: block !important;
        }
    }
}

// [converter] $parent hack
@mixin responsive-invisibility($parent) {
  #{$parent} {
    display: none !important;
  }
}


// TRONCATURES ————————————————————————————————————————————————————————————————————————————————————————————————
// troncature 1 ligne
@mixin truncate($width-truncate) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: $width-truncate;
}

// hauteur déroulée avec transition
@mixin transitioned-height ($lineHeight, $lineCount: 1, $easing: cubic-bezier(0.77, 0, 0.175, 1)) {
    @include transition(max-height 0.25s $easing 0s);
    min-height: $lineHeight * $lineCount;
    max-height: $lineHeight * $lineCount;
    overflow: hidden;

    &.ouvert {
        max-height: 200rem;
        &:before,
        &:after {
            opacity: 0;
        }
    }
}

        // troncature multiligne //
        @mixin multi-line-ellipsis($lineHeight: 1.6em, $lineCount: 1, $bgColor: transparent) {
            display            : block;
            -webkit-box-orient : vertical;
            position           : relative;

            line-height        : $lineHeight;
            overflow           : hidden;
            text-overflow      : ellipsis;
            padding            : 0;

            height             : calc(#{$lineHeight} * #{$lineCount});

            @supports (-webkit-line-clamp: 1)  {
                display            : -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp : $lineCount;
            }
        }

// Permet de s'assurer qu'un texte ne dépasse pas (coupe seche, sans les "..." qui ont besoin d'un texte justifié)
@mixin multi-line-truncate($lineHeight: 1.6em, $lineCount: 1){
    overflow: hidden;
    position: relative;
    line-height: $lineHeight;
    height: $lineHeight * $lineCount;
}


// GRILLE ————————————————————————————————————————————————————————————————————————————————————————————————
@function grid-base-calc($i, $max-col) {
    @return calc($i / $max-col  * 100);
}
@function grid-col-base-width($i, $max-col) {
    @return grid-base-calc($i, $max-col) + '%';
}
@function grid-gutter-width($i, $max-col, $grid-gutter) {
    @return calc($grid-gutter - ($grid-gutter * grid-base-calc($i, $max-col) / 100));
}

@mixin grid-col-definition($breakpoint, $max-col) {
    @for $i from 1 through $max-col {
        .su-col-#{$breakpoint}-#{$i} {
            flex: 0 0 calc(#{grid-col-base-width($i, $max-col)} - #{grid-gutter-width($i, $max-col, $grid-gutter)});
            max-width: calc(#{grid-col-base-width($i, $max-col)} - #{grid-gutter-width($i, $max-col, $grid-gutter)});
        }
    }
}

@mixin grid-offset-definition($breakpoint, $max-col, $grid-gutter) {
    @for $i from 1 through $max-col {
        .su-offset-#{$breakpoint}-#{$i} {
            margin-left: calc(#{grid-col-base-width($i, $max-col)} - #{grid-gutter-width($i, $max-col, $grid-gutter)} + #{$grid-gutter});
        }
    }
}

@mixin grid-filling-definition($breakpoint, $max-col, $grid-gutter) {
    @for $i from 1 through $max-col {
        .su-filling-#{$breakpoint}-#{$i} {
            margin-right: calc(#{grid-col-base-width($i, $max-col)} - #{grid-gutter-width($i, $max-col, $grid-gutter)} + #{$grid-gutter});
        }
    }
}

// IMPORT DES FONTES ————————————————————————————————————————————————————————————————————————————————————————————————
@mixin import-fonts() {
    @if $font-import-use-local == null {
        @font-face {
            font-family: $font-import-name;
            src: url('#{$font-import-url}-Regular.woff2') format('woff2');
            font-weight: 400;
            font-style: normal;
            font-display: swap;
        }

        @if $font-import-medium {
            @font-face {
                font-family: $font-import-name;
                src: url('#{$font-import-url}-Medium.woff2') format('woff2');
                font-weight: 500;
                font-style: normal;
                font-display: swap;
            }
        }

        @if $font-import-bold {
            @font-face {
                font-family: $font-import-name;
                src: url('#{$font-import-url}-Bold.woff2') format('woff2');
                font-weight: 700;
                font-style: normal;
                font-display: swap;
            }
        }

    } @else {
        @font-face {
            font-family: $font-import-name;
            src: url('#{$font-import-directory}/#{$font-import-path}#{$font-import-name}-Regular.woff2') format('woff2');
            font-weight: 400;
            font-style: normal;
            font-display: swap;
        }

        @if $font-import-medium {
            @font-face {
                font-family: $font-import-name;
                src: url('#{$font-import-directory}/#{$font-import-path}#{$font-import-name}-Medium.woff2') format('woff2');
                font-weight: 500;
                font-style: normal;
                font-display: swap;
            }
        }

        @if $font-import-bold {
            @font-face {
                font-family: $font-import-name;
                src: url('#{$font-import-directory}/#{$font-import-path}#{$font-import-name}-Bold.woff2') format('woff2');
                font-weight: 700;
                font-style: normal;
                font-display: swap;
            }
        }
    }
}
