/*
    Styles du composant [Duration] de SipaUI
*/
@import "variables";

// ICONES --------------------------------------------------------------------------------------/
.sipaui {
    .su-duration {
        font-family: $font-family-duration;
        font-style: $font-style-duration;
        font-weight: $font-weight-duration;
        white-space: nowrap;
    }
    .su-media-container .su-duration {
        display: inline-block;
        height: $height-duration-media-container;
        line-height: $height-duration-media-container;
        //white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: $padding-duration-media-container;
        background: $background-duration-media-container;
        backdrop-filter: $backdrop-filter-duration-media-container;
        border-radius: $border-radius-duration-media-container;
        color: $color-duration-media-container;
        font-size: $font-size-duration-media-container;
    }
    .su-metadata .su-duration {
        color: $color-duration-metadata;
        font-size: $font-size-duration-metadata;
    }
    .su-focus,
    .su-card,
    .su-teaser-big {
        .su-duration {
            position: absolute;
            top: $top-duration-on-picture;
            right: $right-duration-on-picture;
            bottom: $bottom-duration-on-picture;
            left: $left-duration-on-picture;
            z-index: 1;
        }
    }
    .su-focus,
    .su-teaser-big {
        .su-duration {
            border-radius: $border-radius-duration-on-picture;
        }
    }
    .su-teaser,
    .su-focus-image {
        .su-duration {
            display: none;
        }
    }
}

@media screen and (max-width: $max-breakpoint-md) {
    .sipaui {
        .su-teaser-big {
            .su-duration {
                display: none;
            }
        }
    }
}
