/*
    Styles du composant [errors] de SipaUI
*/
@import "variables";



.sipaui {
    .su-error-message {
        display: none;
        font-size: $font-size-error;
        color: $color-error;
        margin-top: var(--marge-xs);
    }
    .su-error {
        .su-error-message {
            display: block;
        }

        #{$inputTextPlus},
        .su-select select {
            border: $border-input-error !important;
        }
        input[type="checkbox"]:not(:checked),
        .su-input-checkbox:not(:checked),
        input[type="radio"]:not(:checked),
        .su-input-radio:not(:checked) {
            border-color: $color-error;
        }
        #{$inputTextPlus},
        .su-select {
            + .su-error-message,
            + .su-input-actions-area + .su-error-message {
                margin-left: $margin-left-error-message;
            }
        }
    }
}

// NÉGATIF ---------------------------------------
.sipaui {
    &.su-negative .su-error,
    .su-negative .su-error,
    .su-error.su-negative {
        .su-error-message {
            color: $color-error-negative;
        }
        .su-error {
            #{$inputTextPlus},
            .su-select select {
                border: $border-input-error-negative !important;
            }
            input[type="checkbox"]:not(:checked),
            .su-input-checkbox:not(:checked),
            input[type="radio"]:not(:checked),
            .su-input-radio:not(:checked) {
                border-color: $color-error-negative;
            }
        }
    }
}












