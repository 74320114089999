/*
    Styles du composant [Badges] de SipaUI
*/
@import "variables";

// ICONES --------------------------------------------------------------------------------------/
.sipaui {
    .su-badge::before {
        content: attr(aria-description);
        display: inline-block;
        height: $height-badge;
        line-height: $height-badge;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: $padding-badge;
        background: $background-badge;
        border-radius: $border-radius-badge;
        color: $color-badge;
        font-size: $font-size-badge;
        font-family: $font-family-badge;
        font-style: $font-style-badge;
        font-weight: $font-weight-badge;
    }
    .su-badge.su-subscription::before {
        background: $background-badge-subscription;
        color: $color-badge-subscription;
    }
    .su-media-container,
    .su-focus-image {
        .su-badge {
            position: absolute;
            top: $top-badge-on-picture;
            right: $right-badge-on-picture;
            bottom: $bottom-badge-on-picture;
            left: $left-badge-on-picture;
            max-width: calc(100% - $top-badge-on-picture - $left-badge-on-picture);
            z-index: 1;
        }
        &:has([class*="su-media-icons"]),
        &:has([class*="su-button-fullscreen"]) {
            .su-badge {
                max-width: calc(100% - $top-badge-on-picture - $left-badge-on-picture - var(--taille-tactile-mini) - var(--marge-m));
            }
        }
    }
    .su-teaser {
        .su-media-container .su-badge {
            display: none;
        }
    }
}

@media screen and (max-width: $max-breakpoint-md) {
    .sipaui {
        .su-teaser-big {
            .su-media-container .su-badge {
                display: none;
            }
        }
    }
}
