/*
    Styles du composant [steppers] de SipaUI
*/
@import "variables";

.sipaui {
    .su-stepper ol {
        counter-reset: steps;
        display: flex;
    }

    .su-stepper {
        .su-stepper-step {
            counter-increment: steps;
            flex: 1;
            position: relative;
            z-index: 1;

            > div {
                text-align: center;

                &::before {
                    content: counter(steps);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin: 0 auto;
                    text-align: center;
                    vertical-align: middle;
                    height: $size-step-bullet;
                    width: $size-step-bullet;
                    border-radius: $border-radius-step-bullet;
                    border: $border-step-bullet-done;
                    background-color: $background-step-bullet-done;
                    color: $color-step-bullet-done;
                    font-weight: $font-weight-step-bullet;
                }
            }
            span {
                font-size: var(--font-size-s);
                color: $color-step-label-done;
                font-weight: $font-weight-step;
                position: relative;
                padding-top: calc(#{$size-step-bullet} + #{$margin-bottom-step-bullet});
            }
            &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: $height-line-steps;
                background-color: $background-step-line-done;
                top: calc($size-step-bullet / 2);
                left: 50%;
                z-index: -1;
            }
            &:last-child::after {
                display: none;
            }
            &.su-enabled {
                span {
                    color: $color-step-label-enabled;
                }
                &> div::before {
                    color: $color-step-bullet-enabled;
                    border: $border-step-bullet-enabled;
                    background-color: $background-step-bullet-enabled;
                }
                &::after {
                    background-color: $background-step-line-undone !important; //!important pour surcharger les sous-thèmes comme Abonnement
                }
                .su-step-back {
                    display: none;
                }
            }
            &.su-enabled ~ .su-stepper-step,
            &.su-enabled ~ .su-stepper-step:hover {
                span {
                    color: $color-step-label-undone !important;
                }
                &> div::before {
                    border: $border-step-bullet-undone !important;
                    background-color: $background-step-bullet-undone !important;
                    color: $color-step-bullet-undone !important;
                }
                &::after {
                    background-color: $background-step-line-undone;
                }
                .su-step-back {
                    display: none;
                }
            }
        }
        // VERSION NAVIGABLE ---------------------------------/
        &.su-stepper-nav {
            .su-stepper-step:not(.su-enabled) {
                > div:hover::before {
                    border: $border-step-bullet-hover;
                    background-color: $background-step-bullet-hover;
                    color: $color-step-bullet-hover;
                }
                span:hover {
                    color: $color-step-label-hover;
                }
            }
        }
        .su-step-back {
            display:block;
            text-indent: -1000px;
            overflow: hidden;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: none; //pour les buttons
            border: none; //pour les buttons
        }

        // COCHE FINALE ---------------------------------/
        &.su-check {
             .su-stepper-step:last-child {
                  &.su-enabled {
                    &> div::before {
                        content: '';
                        background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iQ2FscXVlXzEiIGRhdGEtbmFtZT0iQ2FscXVlIDEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDIyLjk0IDI1LjA2Ij48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPnZhbGlkZXI8L3RpdGxlPjxwb2x5Z29uIGNsYXNzPSJjbHMtMSIgcG9pbnRzPSIxMS45NyAyNS4wNSAwIDEzLjA4IDIuMTIgMTAuOTYgMTEuMDUgMTkuODkgMjAuMjIgMCAyMi45NCAxLjI2IDExLjk3IDI1LjA1Ii8+PC9zdmc+) center no-repeat;
                        background-size: 50%;
                        background-color: $background-step-bullet-enabled;
                    }
                }
            }
        }

        // ABONNEMENT ---------------------------------/
        &.su-subscription {
            .su-stepper-step {
                span {
                    //color: $color-step-label-done-subscription;
                }
                &> div::before {
                    border: $border-step-bullet-done-subscription;
                    //background-color: $background-step-bullet-done-subscription;
                    color: $color-step-bullet-done-subscription;
                }
                &::after {
                    background: $background-step-line-done-subscription;
                }
                &.su-enabled {
                    &> div::before {
                        color: $color-step-bullet-enabled-subscription;
                        border: $border-step-bullet-enabled-subscription;
                        background-color: $background-step-bullet-enabled-subscription !important;
                    }
                }
            }
            &.su-stepper-nav {
                .su-stepper-step:not(.su-enabled) {
                    > div:hover::before {
                        border: $border-step-bullet-hover-subscription;
                        background-color: $background-step-bullet-hover-subscription;
                        color: $color-step-bullet-hover-subscription;
                    }
                    span:hover {
                        color: $color-step-bullet-hover-subscription;
                    }
                }
            }
        }
    }
}

@media (max-width: $max-breakpoint-sm) {
    .sipaui .su-stepper .su-stepper-step span {
        display: none;
    }
}























