/*
    Styles du composant [Standfirst] de SipaUI
*/
@import "variables";


// CHAPEAU GÉNÉRIQUE-----------------------------------------------------------------------------------------/
.sipaui {
    .su-standfirst {
        font-family: $font-family-standfirst;
        color: $color-standfirst;
        font-style: normal;
    }
}


// CHAPEAU LISTE D’ARTICLES -----------------------------------------------------------------------------------------/
.sipaui {
    .su-articles-list {
        .su-standfirst {
            font-size: $font-size-standfirst-articles-list;
            font-weight: $font-weight-standfirst-articles-list;
            line-height: $line-height-standfirst-articles-list;
        }
    }
}

// CHAPEAU ARTICLE COMPLET ------------------------------------------------------------------------------------------/
.sipaui {
    .su-article .su-standfirst,
    .su-standfirst.su-article  {
        font-size: $font-size-standfirst-article;
        font-weight: $font-weight-standfirst-article;
        line-height: $line-height-standfirst-article;
    }
}




//
/* -- DESKTOP ONLY ---------------------------------------------------------------------------------------------------------------------------------- */
//

@media only screen and (min-width: $min-breakpoint-lg) {
    // CHAPEAU LISTE D’ARTICLES -----------------------------------------------------------------------------------------/
    .sipaui {
        .su-articles-list {
            .su-standfirst {
                font-size: $font-size-standfirst-articles-list-desktop;
            }
        }
    }

    // CHAPEAU ARTICLE COMPLET ------------------------------------------------------------------------------------------/
    .sipaui {
        .su-article {
            .su-standfirst {
                font-size: $font-size-standfirst-article-desktop;
            }
        }
    }
}


@if $do-not-use-negative == null {
    .sipaui {
        .su-negative .su-standfirst,
        .su-negative.su-standfirst {
            color: $font-color-standfirst-negative;
        }
    }
}
