/*
    Styles du composant [media-icons] de SipaUI
*/
@import "variables";

// ICONES --------------------------------------------------------------------------------------/
.sipaui {
    [class*="su-media-icons-"] {
        position: absolute;
        width: $size-media-icon-background;
        height: $size-media-icon-background;
        top: $top-media-icon-background;
        right: $right-media-icon-background;
        background: $background-media-icon-background;
        border-radius: $border-radius-media-icon-background;
        // Pour rendre invisible le texte à l’intérieur
        overflow: hidden;
        color: $background-media-icon-background;
        font-size: 1px;
        
        &::before {
            content: "";
            font-family: su-icons!important;
            font-size: $font-size-media-icon;
            color: $color-media-icon;
            position: absolute;
            width: $size-media-icon;
            height: $size-media-icon;
            line-height: $size-media-icon;
            top: calc(($size-media-icon-background - $size-media-icon)/2);
            left: calc(($size-media-icon-background - $size-media-icon)/2);
         }
    }
    .su-media-icons-slideshow::before {        
        content: "\e910";
        transform: translate(-2px, -1px); //pour compenser le décentrage du texte
    }
    .su-media-icons-video::before {
        content: "\e90f";
        transform: translate(-1px, -1px); //pour compenser le décentrage du texte
    }
    .su-media-icons-podcast::before {        
        content: "\e90d";
        transform: translate(-2px, -1px); //pour compenser le décentrage du texte
    }
    [class*="su-media-icons-"].su-small {
        width: $size-media-icon-small-background;
        height: $size-media-icon-small-background;
        top: $top-media-icon-small-background;
        right: $right-media-icon-small-background;
         
        &::before {
            width: $size-media-icon-small;
            height: $size-media-icon-small;
            line-height: $size-media-icon-small;
            font-size: $font-size-media-icon-small;
            top: calc(($size-media-icon-small-background - $size-media-icon-small)/2);
            left: calc(($size-media-icon-small-background - $size-media-icon-small)/2);
        }
    }
}
