/*
    Styles du composant [Veil] de SipaUI
*/
@import "variables";

// Animation d’ouverture

.sipaui {
    .su-veil {
       display: block;
       position: static;
       top: 0;
       right: 0;
       bottom: 0;
       left: 0;
       width: auto;
       height: auto;
       z-index: 10200;
       background: $background-veil;
       opacity: 0;
       transition: $animation-veil;
       backdrop-filter: $backdrop-filter-veil;
       
       &.su-veil-in {
           opacity: 1;
           position: fixed;
       }
    }
}
