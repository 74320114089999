/*
    Styles du composant [links] de SipaUI
*/
@import "variables";


body a {
    text-decoration-skip: auto !important; // Safari
    text-decoration-skip-ink: auto !important; // Les autres
}

// LIEN NON ÉDITORIAL ------------------------------------------------------------------------------------------/
// Tout est dans typo.scss


// LIEN ÉDITORIAL ------------------------------------------------------------------------------------------/
.sipaui {
    .su-article a,
    a.su-link {
        color: $color-link-article;
        text-decoration: $text-decoration-link-article;
        padding-bottom: 1px;
        border-bottom: $border-bottom-link-article;

        &:where(:hover, :focus, :active, :visited) {
            color: $color-link-article-hover;
            text-decoration: $text-decoration-link-article-hover;
            border-color: $border-color-link-article-hover;
            opacity: $opacity-link-article-hover;
        }
        &.su-disabled {
            &:where(:hover, :focus, :active, :visited) {
                color: $color-link-article;
                border-color: $color-link-article;
                opacity: var(--opacite-inactif);
            }
        }
    }
    // Fond sombre
    .su-article.su-negative a,
    .su-article .su-negative a,
    a.su-link.su-negative {
        color: $color-link-article-negative;
        border-bottom: $border-bottom-link-article-negative;

        &:where(:hover, :focus, :active, :visited) {
            color: $color-link-article-negative-hover;
            border-color: $border-color-link-article-negative-hover;
        }
    }

    // Lien invisible
    .su-article a.su-not-link,
    a.su-not-link {
        color: $font-color-standard;
        text-decoration: none;
        padding-bottom: 0;
        border-bottom: none;
    }
}

// LIEN LÉGER ------------------------------------------------------------------------------------------/
.sipaui {
    a.su-link-light,
    .su-article a.su-link-light,
    &.su-negative a.su-link-light,
    .su-negative a.su-link-light,
    a.su-link-light.su-negative  {
        color: inherit;
        text-decoration: underline;
        border: none;
        padding: 0;

        &:where(:hover, :focus, :active, :visited) {
            color: inherit;
            text-decoration: underline;
            opacity: $opacity-hover-link-light;
        }
    }
}

// LIEN BOUTON ------------------------------------------------------------------------------------------/
.sipaui {
    a.su-link-button {
        color: $color-link-button;
        text-decoration: $text-decoration-link-button;
        font-weight: $font-weight-link-button;
        font-size: $font-size-link-button;
        display: inline-block;
        height: $height-link-button;
        line-height: $height-link-button;
        border-bottom: 0;

        &:where(:hover, :focus, :active) {
            color: $color-link-button-hover;
            text-decoration: $text-decoration-link-button-hover;
            opacity: $opacity-link-button-hover;
        }
        &.su-disabled {
            &:where(:hover, :focus, :active) {
                text-decoration: $text-decoration-link-button;
                opacity: var(--opacite-inactif);
                color: $color-link-button;
            }
        }
    }
    // Fond sombre
    .su-negative a.su-link-button,
    a.su-link-button.su-negative {
        color: $color-link-button-negative;
    }
}

