/*
    Styles du composant [premium-title] de SipaUI
*/
@import "variables";

// GÉNÉRIQUE
.sipaui {
    .su-premium {
        .su-title::after,
        &.su-at-start .su-title::before,
        h1::before {
            content: $content-icone-premium-bloque;
            position: relative;
            top: $top-icone-premium-a-droite;
            font-family: 'su-icons';
            color: $color-icone-premium;
            font-size: $font-size-icone-premium;
            font-weight: $font-weight-icone-premium;
            line-height: .1;
        }
        &.su-at-start .su-title::before {
            margin-left:  $margin-left-icone-premium-alignement;
        }
        h1::before {
            margin-left:  $margin-left-icone-premium-alignement;
        }
        &.su-at-start .su-title::after {
            display: none;
        }
    }
    &.su-subscribed,
    .su-subscribed {
        .su-premium {
            .su-title::after,
            &.su-at-start .su-title::before,
            h1::before {
                content: $content-icone-premium-debloque;
                left: .13em;
            }
            &.su-at-start .su-title::before,
            h1::before {
                margin-right: $margin-right-icone-premium-h1-ouverte;
            }
        }
        .su-article.su-premium {
            .su-premium-info::before {
                content: $content-icone-premium-debloque;
            }
        }
    }
}

// DÉTAIL ARTICLE
.sipaui {
    .su-article.su-premium {
        .su-premium-info {
            color: $color-premium-info;
            font-size: $font-size-premium-info;
            text-align: $text-align-premium-info;
            width: $width-premium-info;
            height: $height-premium-title;
            line-height: $line-height-premium-info;
            padding: $padding-premium-info;

            &::before {
                content: $content-icone-premium-bloque;
                display: block;
                font-family: 'su-icons';
                font-size: $font-size-icone-premium-info;
                line-height: $line-height-icone-premium-info;
                transform: $transform-icone-premium-info;
            }
        }
    }
    &.su-subscribed,
    .su-subscribed {
        .su-article.su-premium {
            .su-premium-info:before {
                margin-left: 19px;
            }
        }
    }
}

@media screen and (max-width: $max-breakpoint-md) {
    .sipaui {
        .su-article.su-premium {
            .su-premium-info {
                clip: rect(1px,1px,1px,1px);
                width: 1px;
                height: 1px;
                overflow: hidden;
                position: absolute;
            }
        }
    }
}

@media screen and (min-width: $min-breakpoint-lg) {
    .sipaui {
        .su-article.su-premium {
            .su-premium-info {
                float: left;
            }
            h1 {
                display: flex;
                min-height: $height-premium-title;
                align-items: center;
                padding: $padding-premium-title;
                border-left: $border-left-premium-title;
                margin-bottom: $margin-bottom-premium-title;

                &::before {
                    display: none;
                }
            }
        }
    }
}






// ABONNÉS PROS --------------------------------------------------------------------------
// GÉNÉRIQUE
.sipaui {
    .su-premium.su-pro {
        .su-title::after,
        &.su-at-start .su-title::before,
        h1::before,
        &.su-article .su-premium-info::before {
            content: "PRO";
            font-family: $font-family-icone-premium-pro;
            color: $color-icone-premium-pro;
            background: $background-icone-premium-pro;
            font-size: $font-size-icone-premium-pro;
            font-weight: $font-weight-icone-premium-pro;
            border-radius: $border-radius-icone-premium-pro;
            padding: $padding-icone-premium-pro;
            line-height: $line-height-icone-premium-pro;
            top: $top-icone-premium-pro;
            margin-left: $margin-icone-premium-pro;
        }
        &.su-at-start .su-title::before {
            margin-left: 0;
            margin-right: $margin-icone-premium-pro;
        }
    }
}

// DÉTAIL ARTICLE
.sipaui {
    .su-premium.su-pro {
        h1::before {
            margin: $margin-left-premium-h1-pro;
            font-size: $font-size-icone-premium-h1-pro;
            top: $top-icone-premium-h1-pro;
            padding: $padding-premium-h1-pro;
        }
        &.su-article .su-premium-info::before {
            font-size: $font-size-premium-info-pro;
            width: $width-premium-info-pro;
            margin: $margin-premium-info-pro;
            padding: $padding-premium-info-pro;
        }
    }
}



