/*
    Styles du composant [loaders] de SipaUI
*/
@import "variables";

.sipaui {
    // BULLE BASE
    .su-bubble {
        display: inline-flex; // inline-flex au lieu de flex pour donner un contexte de block et aligner éventuellement avec du texte.
        justify-content: center;
        align-items: center;
        background: $background-bubble;
        border: $border-bubble;
        border-radius: $border-radius-bubble;
        width: $width-bubble;
        height: $height-bubble;
        color: $color-bubble;
        font-size: $font-size-bubble;

        &.su-over {
            :first-child {
                order: 2;
                font-size: .8rem;
                transform: translateY(-2px);
            }
        }
    }
    // BULLE SECONDAIRE
    .su-bubble {
        &.su-secondary {
            background: $background-bubble-secondary;
            border: $border-bubble-secondary;
            color: $color-bubble-secondary;
        }
    }
    // BULLE SMALL
    .su-bubble {
        &.su-small {
            width: $width-bubble-small;
            height: $height-bubble-small;

            * {
                display: none;
            }
        }
    }
    // NÉGATIVE
    &.su-negative,
    .su-negative {
        .su-bubble {
            background: $background-bubble-negative;
        }
        .su-bubble.su-secondary {
            border-color: $border-color-bubble-secondary-negative;
            color: $color-bubble-secondary-negative;
        }
    }
}

@media (max-resolution: 140dpi) { // Pour supprimer un pb de rendu de la bordure sur les écrans à faible densité
    $margin-bubble-low-resolution: 1px;
    $width-bubble-low-resolution: calc($width-bubble - 2px);
    $height-bubble-low-resolution: $width-bubble-low-resolution;
    $width-bubble-small-low-resolution: calc($width-bubble-small - 2px);
    $height-bubble-small-low-resolution: $width-bubble-small-low-resolution;

    body {
        //background: pink !important;
    }

    .sipaui {
        .su-bubble {
            border: none;
            outline: $border-bubble; // Utilisation d’outline à la place de border pour contourner le mauvais affichage des bordures sur les écrans basse définition.
            margin: $margin-bubble-low-resolution; // pour englober l’outline dans la taille réservée à la bulle.
            width: $width-bubble-low-resolution;
            height: $height-bubble-low-resolution;
        }
        // BULLE SECONDAIRE
        .su-bubble {
            &.su-secondary {
                border: none;
                outline: $border-bubble-secondary;
            }
        }
        // BULLE SMALL
        .su-bubble {
            &.su-small {
                width: $width-bubble-small-low-resolution;
                height: $height-bubble-small-low-resolution;
            }
        }
        // NÉGATIVE
        &.su-negative,
        .su-negative {
            .su-bubble.su-secondary {
                outline-color: $border-color-bubble-secondary-negative;
            }
        }
    }
}

