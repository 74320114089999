/*
    Styles du composant [Messages] de SipaUI
*/
@import "variables";

.sipaui {
    .su-message {
        position: relative;
        display: none;
        padding: $padding-message;
        padding-left: $padding-left-message;

        &.su-message-in {
            display: block;
        }
        .su-close {
            position: absolute;
            top: $top-croix-fermeture-messages;
            right: $right-croix-fermeture-messages;
        }
        .su-message-icon {
            position: absolute;
            top: $top-icone-message;
            left: $left-icone-message;
            width: $width-icone-message;
            height: $height-icone-message;
            text-align: center; // pour les icônes SVG

            svg {
                max-height: 100%;
                max-width: 100%;
            }
        }
        .su-title {
            margin-right: $margin-right-titre-message;
            margin-bottom: $margin-bottom-titre-message;
            font-family: $font-family-titre-message;
            font-size: $font-size-titre-message;
            font-weight: $font-weight-titre-message;
            line-height: $line-height-titre-message;
        }
        .su-content {
                font-size: $font-size-contenu-message;
            * {
                margin: 0;
            }
            p + p {
                margin-top: $margin-top-paragraphes-message;
            }
            a {
                text-decoration: underline;
            }
        }

        .su-buttons-area {
            padding-top: $padding-top-boutons-message;
        }
        &.su-information {
            background: $background-information-message;

            .su-primary {
                background: $background-bouton-information-message;
            }
            .su-message-icon {
                color: $color-icone-information-message;
            }
        }
        &.su-alert {
            background: $background-alert-message;
            .su-primary {
                background: $background-bouton-alert-message;
            }
            .su-message-icon {
                color: $color-icone-alert-message;
            }
        }
        &.su-success {
            background: $background-succes-message;
            .su-primary {
                background: $background-bouton-succes-message;
            }
            .su-message-icon {
                color: $color-icone-succes-message;
            }
        }
        .su-message-icon {
            &:not(.su-stroke) svg {
                fill: currentColor !important;
                stroke: none !important;
            }
            &.su-stroke svg {
                fill: none !important;
                stroke: currentColor !important;
            }
        }

        &.su-negative {
            .su-message-icon {
                &:not(.su-stroke) svg {
                    fill: currentColor !important;
                    stroke: none !important;
                }
                &.su-stroke svg {
                    fill: none !important;
                    stroke: currentColor !important;
                }
            }
        }
    }
    .su-top,
    .su-fixed-top,
    .su-fixed-bottom {
        right: 0;
        left: 0;
        z-index: 10000;
    }
    .su-top {
        position: absolute;
    }
    .su-fixed-top,
    .su-fixed-bottom {
        position: fixed;
    }
    .su-top,
    .su-fixed-top {
        top: 0;
    }
    .su-fixed-bottom {
        bottom: 0;
    }

    // FOND SOMBRE
    &.su-negative .su-message,
    .su-negative .su-message,
    .su-message.su-negative {
        &.su-information {
            background: $background-information-message-negative;
        }
        &.su-alert {
            background: $background-alert-message-negative;
        }
        &.su-success {
            background: $background-succes-message-negative;
        }
    }
}

@media screen and (min-width: $min-breakpoint-md) {
    .sipaui .su-message {
        padding-left: calc($padding-left-message - $marge-container-md);

        .su-content-area,
        .su-buttons-area {
            max-width: $max-width-container-md; /* 768px */
            padding-left: $marge-container-md;
            padding-right: $marge-container-md;
        }
    }
}

@media screen and (min-width: $min-breakpoint-lg) {
    .sipaui .su-message {
        padding-left: calc($padding-left-message - $marge-container-lg);

        .su-content-area,
        .su-buttons-area {
            max-width: $max-width-container-lg; /* 960px */
            padding-left: $marge-container-lg;
            padding-right: $marge-container-lg;
        }
    }
}

@media screen and (min-width: $min-breakpoint-xl) {
    .sipaui .su-message {
        padding-left: calc($padding-left-message - $marge-container-lg);

        .su-content-area,
        .su-buttons-area {
            max-width: $max-width-container-xl; /* 1280px */
        }
    }
}
