/*
    Styles du composant [labels] de SipaUI
*/
@import "variables";



.sipaui {
    label,
    .su-label {
        color: $color-label;
        font-size: $font-size-label;
        font-weight: $font-weight-label;
        line-height: var(--line-height-s);
        margin-bottom: $margin-bottom-label;
        display: block;
        overflow: hidden;
    }
    .su-label-complement {
        font-weight: var(--font-weight-thin);
        color: $color-label-complement;
        font-size: $font-size-label-complement;
    }
    .su-negative,
    &.su-negative {
        :is(label, .su-label) {
            color: $color-label-negative;
        }
        .su-label-complement {
            color: $color-label-complement-negative;
        }
    }
}














